import { CustomRouterType } from "admin/src/ui/context/CustomRouterContext";
import { generatePath, useHistory, useLocation } from "react-router";
import { RouteParams } from "shared/api/types/society/[societyId]/app-route-config";

// This will be useHubCustomRouter(); only used once on userHubroutes to pass into the `admin/src/ui/context/CustomRouterContext.tsx`
export const useHubCustomRouter = (): CustomRouterType => {
  const location = useLocation();
  const history = useHistory();

  const getFullPathAndParams = (path: string, params?: RouteParams) => {
    const fullParams = { ...(location.state || {}), ...params };
    const fullPath = generatePath(path, fullParams);
    return { fullPath, fullParams };
  };

  return {
    asPath: location.pathname,
    pathname: location.pathname,
    state: location.state,
    replace: (path: string, params?: RouteParams) => {
      const { fullPath, fullParams } = getFullPathAndParams(path, params);
      history.replace(fullPath, fullParams);
    },
    push: (path: string, params?: RouteParams) => {
      const { fullPath, fullParams } = getFullPathAndParams(path, params);
      history.push(fullPath, fullParams);
    },
  };
};
