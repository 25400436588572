import { SessionView } from "shared/mappers/database/session/session";
import { newApiRequest } from "admin/src/ui/api-callouts/utils";
import { removeHTMLTags } from "admin/src/utils/helpers/removeHTMLTags";
import React from "react";
import type { GroupBase, OptionsOrGroups } from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { getSocietyChoicesQuestionNameContract } from "shared/api/contracts/society/societyId/choices/questionName";
import {
  ElementFactory,
  ExpressionValidator,
  Question,
  Serializer,
  settings,
} from "survey-core";
import { SurveyCreator } from "survey-creator-react";
import { ReactQuestionFactory } from "survey-react-ui";
import NewAppForm from "../../common/newform/NewAppForm";
import NewAppTextInput from "../../common/newform/NewAppTextInput";

export const CUSTOM_TYPE_PILLAR_CHOICE_DROPDOWN = "pillar_choice_dropdown";

export class PillarChoiceDropdownModel extends Question {
  getType() {
    return CUSTOM_TYPE_PILLAR_CHOICE_DROPDOWN;
  }
}

(settings as { customIcons: { [index: string]: string } }).customIcons[
  "icon-" + CUSTOM_TYPE_PILLAR_CHOICE_DROPDOWN
] = "icon-dropdown";

export type PillarChoiceDropdownInputProps = {
  creator: SurveyCreator;
  isDisplayMode: boolean;
  question: Question;
  session: SessionView;
};

export type OptionsValuesPillarChoice = {
  value: number;
  label: string;
};

export const PillarChoiceDropdown = (props: PillarChoiceDropdownInputProps) => {
  const {
    pillar_choices_question_name,
    multi_select,
    placeholder_text,
    other_option,
    other_option_text,
    other_placeholder_text,
    min_choices,
    max_choices,
  } = props.question;
  const [otherOption, setOtherOption] = React.useState(false);
  const [otherInputValue, setOtherInputValue] = React.useState<string>("");
  const otherOptionText = removeHTMLTags(other_option_text) ?? "Other";

  const fetchCustomChoices = async (
    inputValue: string,
    loadedOptions: OptionsOrGroups<
      OptionsValuesPillarChoice,
      GroupBase<OptionsValuesPillarChoice>
    >,
    page = 1,
  ) => {
    const pageSize = 25;
    const choicesResponse = await newApiRequest(
      getSocietyChoicesQuestionNameContract,
      {
        params: {
          societyId: props.session.societyId ?? 0,
          questionName: pillar_choices_question_name,
        },
      },
      {
        text: inputValue,
      },
      {
        page,
        pageSize,
      },
    );

    const otherOption: OptionsValuesPillarChoice = {
      value: -1,
      label: otherOptionText,
    };

    const options = choicesResponse.results.map((choices) => ({
      value: choices.customQuestionChoiceId,
      label: choices.text,
    }));

    const hasMore =
      choicesResponse.page < Math.ceil(choicesResponse.totalResults / pageSize);

    return {
      options: other_option ? [...options, otherOption] : options,
      hasMore,
      additional: page + 1,
    };
  };

  if (
    multi_select &&
    Array.isArray(props.question.value) &&
    min_choices > props.question.value?.length &&
    props.question.getType() === CUSTOM_TYPE_PILLAR_CHOICE_DROPDOWN
  ) {
    const expressionValidator = new ExpressionValidator();
    expressionValidator.expression = `{${props.question.name}}.length >= ${min_choices}`;
    expressionValidator.text = `You must select at least ${min_choices} choices.`;
    props.question.validators = [expressionValidator];
  } else {
    props.question.validators = [];
  }

  return (
    <div>
      <AsyncPaginate
        loadOptions={fetchCustomChoices}
        debounceTimeout={500}
        isMulti={multi_select}
        placeholder={removeHTMLTags(placeholder_text) ?? "Select..."}
        value={
          props.question.value && Array.isArray(props.question.value)
            ? props.question.value.map((v) => ({
                value: v,
                label: v,
              }))
            : props.question.value
            ? {
                value: props.question.value,
                label: props.question.value,
              }
            : null
        }
        onChange={(value: any) => {
          if (
            max_choices &&
            Array.isArray(value) &&
            value.length > max_choices
          ) {
            value.pop();
          }

          const isOther = Array.isArray(value)
            ? value.some((v) => v.label === otherOptionText)
            : value.label === otherOptionText;

          setOtherOption(isOther);
          if (isOther && otherInputValue) {
            if (multi_select) {
              props.question.value = value.map((v: OptionsValuesPillarChoice) =>
                v.label === otherOptionText ? otherInputValue : v.label,
              );
            } else {
              props.question.value = otherInputValue;
            }
          } else {
            if (multi_select) {
              props.question.value = value.map(
                (v: OptionsValuesPillarChoice) => v.label,
              );
            } else {
              props.question.value = value.label;
            }
          }
        }}
      />
      {otherOption && (
        <NewAppForm
          className="mt-4"
          handleSubmit={() => {
            //void
          }}
          handleChanged={(values) => {
            multi_select && setOtherInputValue(values.other);
            props.question.value = multi_select
              ? [
                  ...props.question.value.filter(
                    (val: any) => val !== otherOptionText,
                  ),
                  values.other,
                ]
              : values.other;
          }}
        >
          <NewAppTextInput
            name="other"
            placeholder={removeHTMLTags(other_placeholder_text) ?? "Select..."}
          />
        </NewAppForm>
      )}
    </div>
  );
};

export const RegisterPillarChoiceDropdownComponent = (session: SessionView) => {
  ElementFactory.Instance.registerElement(
    CUSTOM_TYPE_PILLAR_CHOICE_DROPDOWN,
    (name) => {
      return new PillarChoiceDropdownModel(name);
    },
  );

  Serializer.addClass(
    CUSTOM_TYPE_PILLAR_CHOICE_DROPDOWN,
    [{ name: "height", default: "200px", category: "layout" }],
    function () {
      return new PillarChoiceDropdownModel("");
    },
    "question",
  );

  Serializer.addProperty(CUSTOM_TYPE_PILLAR_CHOICE_DROPDOWN, {
    name: "pillar_choices_question_name",
    displayName: "Pillar Choice Category",
    type: "dropdown",
    category: "general",
    choices:
      session.society?.choiceCategory?.map(
        (category) => category.questionName ?? "",
      ) ?? [],
  });

  Serializer.addProperty(CUSTOM_TYPE_PILLAR_CHOICE_DROPDOWN, {
    name: "placeholder_text",
    displayName: "Placeholder text",
    type: "text",
    category: "general",
  });

  Serializer.addProperty(CUSTOM_TYPE_PILLAR_CHOICE_DROPDOWN, {
    name: "multi_select",
    displayName: "Multi select",
    type: "boolean",
    default: false,
    category: "general",
  });

  Serializer.addProperty(CUSTOM_TYPE_PILLAR_CHOICE_DROPDOWN, {
    name: "min_choices",
    displayName: "Minimum choices for multi select",
    type: "number",
    category: "general",
    visibleIf: function (obj: Question) {
      return obj.multi_select === true;
    },
  });

  Serializer.addProperty(CUSTOM_TYPE_PILLAR_CHOICE_DROPDOWN, {
    name: "max_choices",
    displayName: "Maximum choices for multi select",
    type: "number",
    category: "general",
    visibleIf: function (obj: Question) {
      return obj.multi_select === true;
    },
  });

  Serializer.addProperty(CUSTOM_TYPE_PILLAR_CHOICE_DROPDOWN, {
    name: "other_option",
    displayName: "Other option",
    type: "boolean",
    default: false,
    category: "general",
  });

  Serializer.addProperty(CUSTOM_TYPE_PILLAR_CHOICE_DROPDOWN, {
    name: "other_option_text",
    displayName: "Other option text",
    type: "text",
    category: "general",
    visibleIf: function (obj: Question) {
      return obj.other_option === true;
    },
  });

  Serializer.addProperty(CUSTOM_TYPE_PILLAR_CHOICE_DROPDOWN, {
    name: "other_placeholder_text",
    displayName: "Other placeholder text",
    type: "text",
    category: "general",
    visibleIf: function (obj: Question) {
      return obj.other_option === true;
    },
  });

  ReactQuestionFactory.Instance.registerQuestion(
    CUSTOM_TYPE_PILLAR_CHOICE_DROPDOWN,
    (props: any) => {
      return React.createElement(PillarChoiceDropdown, {
        ...props,
        session,
      });
    },
  );
};
