import { InstitutionTypeView } from "shared/mappers/database/profile/institution-type";
import {
  ComponentCollection,
  ICustomQuestionTypeConfiguration,
  ItemValue,
  Question,
  QuestionCustomModel,
  Serializer,
  SurveyModel,
} from "survey-core";
import {
  CreatorBase,
  ModifiedEvent,
  SurveyCreatorModel,
} from "survey-creator-core";
export const custom_question_profile_institution_type =
  "profileinstitutiontype";

export const profileInstitutionTypeQuestion = async (
  institionTypes: InstitutionTypeView[],
  creator?: SurveyCreatorModel,
) => {
  if (creator) {
    creator.onDesignerSurveyCreated.add(
      async (sender: SurveyCreatorModel, options) => {
        if (
          sender.toolbox.getItemByName(custom_question_profile_institution_type)
        ) {
          const toolBoxItem = sender.toolbox.getItemByName(
            custom_question_profile_institution_type,
          );
          if (
            options.survey.getQuestionByName(
              custom_question_profile_institution_type,
            )
          ) {
            sender.toolbox.removeItem(custom_question_profile_institution_type);
          }
          creator.onQuestionAdded.add(async (sender: CreatorBase, options) => {
            if (
              options.question.getType() ===
              custom_question_profile_institution_type
            ) {
              options.question.name = custom_question_profile_institution_type;
              sender.toolbox.removeItem(
                custom_question_profile_institution_type,
              );
            }
          });
          creator.onModified.add(
            async (sender: CreatorBase, options: ModifiedEvent) => {
              if (
                options.type === "OBJECT_DELETED" &&
                options.target &&
                options.target.getType() ===
                  custom_question_profile_institution_type
              ) {
                sender.toolbox.addItem(toolBoxItem);
              }
            },
          );
        }
      },
    );
  }

  const questionTitle = "Profile Institution Type";
  const ProfileInstitutionTypeQuestion: ICustomQuestionTypeConfiguration = {
    name: custom_question_profile_institution_type,
    title: questionTitle,
    questionJSON: {
      name: custom_question_profile_institution_type,
      type: "dropdown",
      placeholder: "Select an institution type...",
      choices: institionTypes.map((type) => {
        return type.name;
      }),
    },
    onItemValuePropertyChanged: () => {
      //null
    },
    onCreated(): void {
      //null
    },
    onInit() {
      //
    },
    onLoaded(question: QuestionCustomModel) {
      if (!question.getSurvey()) {
        return;
      }
      if (institionTypes) {
        const type_list = institionTypes.map((value: InstitutionTypeView) => {
          return new ItemValue(value.id, value.name);
        });
        Serializer.setObjPropertyValue(question, "institutionTypes", type_list);
      }
      const surveyModel = question.getSurvey() as unknown as SurveyModel;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
      surveyModel.onValueChanged.add((sender: SurveyModel, options: any) => {
        const surveyData = sender.getSurveyData();
        if (
          surveyData.getValue("profile_type") === "Institution" &&
          surveyData.getValue(custom_question_profile_institution_type)
        ) {
          surveyData.setValue(
            "institution_type",
            surveyData.getValue(custom_question_profile_institution_type),
            false,
            false,
          );
        }
      });

      const choices = Serializer.getObjPropertyValue(
        question,
        "institutionTypes",
      );
      question.choices = choices;
      const defaultValueProperty = Serializer.findProperty(
        custom_question_profile_institution_type,
        "defaultValue",
      );
      defaultValueProperty.setChoices(choices);
      const defaultValue = Serializer.getObjPropertyValue(
        question,
        "defaultValue",
      );
      if (defaultValue) {
        question.value = defaultValue;
      }
      Serializer.setObjPropertyValue(
        question,
        "clearIfInvisible",
        "onComplete",
      );
      question.getPropertyByName("clearIfInvisible").visible = false;
    },
    onAfterRender: function (): void {
      //null
    },
    onAfterRenderContentElement: function (): void {
      //null
    },
    onValueChanged: function (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      question: QuestionCustomModel,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      name: string,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      newValue: string,
    ): void {
      //null
    },
    onPropertyChanged: function (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      question: Question,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      propertyName: string,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
      newValue: any,
    ): void {
      //null
    },
  };

  if (
    !ComponentCollection.Instance.getCustomQuestionByName(
      custom_question_profile_institution_type,
    )
  ) {
    ComponentCollection.Instance.add(ProfileInstitutionTypeQuestion);
  }
  return ProfileInstitutionTypeQuestion;
};
