import { SessionStorageKey } from "admin/src/constants";
import { FileView } from "shared/mappers/database/file";
import ApiRequest from "admin/src/ui/api-callouts/api-request-builder";
import { HttpMethod } from "admin/src/ui/types/common/http";
import { getSessionStorageItem } from "admin/src/utils/session-storage";
import { TokenView } from "shared/mappers/database/token/token";

export type GetDownloadFileUrlCalloutProps = {
  fileIdOrSlug: number | string;
  societyId: number;
  baseUrl?: string;
};
export const getDownloadFileUrlCallout = async ({
  fileIdOrSlug,
  societyId,
  baseUrl,
}: GetDownloadFileUrlCalloutProps): Promise<string> => {
  const apiUrl = `${
    baseUrl ?? ""
  }/api/v1/society/${societyId}/files/${fileIdOrSlug}?returnLink=true`;

  const response = await ApiRequest(apiUrl, HttpMethod.GET);

  return response?.body?.url;
};

export const getFileInfoCallout = async ({
  societyId,
  fileIdOrSlug,
  baseUrl,
}: {
  societyId: number;
  fileIdOrSlug?: string | number;
  baseUrl?: string;
}): Promise<FileView> => {
  const apiUrl = `${
    baseUrl ?? ""
  }/api/v1/society/${societyId}/files/${fileIdOrSlug}?returnInfo=${true}`;

  const response = await fetch(apiUrl);

  const res = await response.json();

  return res.file;
};

export const getDownloadFileLinkCallout = async (
  fileIdOrSlug?: number | string,
  society_id?: number,
  baseUrl?: string,
): Promise<any> => {
  const societyId =
    society_id || getSessionStorageItem(SessionStorageKey.SOCIETY_ID);
  const apiUrl = `${
    baseUrl ?? ""
  }/api/v1/society/${societyId}/files/${fileIdOrSlug}?returnLink=${true}`;

  const response = await fetch(apiUrl);

  const res = await response.json();

  return res.body;
};

export const getFileTokensCallout = async ({
  societyId,
  fileIdOrSlug,
  baseUrl,
}: {
  societyId: number;
  fileIdOrSlug: number;
  baseUrl?: string;
}): Promise<TokenView[]> => {
  const apiUrl = `${
    baseUrl ?? ""
  }/api/v1/society/${societyId}/files/${fileIdOrSlug}/token`;

  const response = await ApiRequest(apiUrl, HttpMethod.GET);

  return response?.body;
};

export const createFileTokenCallout = async ({
  societyId,
  fileIdOrSlug,
  baseUrl,
  expirationDate,
}: {
  societyId: number;
  fileIdOrSlug: number;
  baseUrl?: string;
  expirationDate: string | null;
}): Promise<TokenView> => {
  const apiUrl = `${
    baseUrl ?? ""
  }/api/v1/society/${societyId}/files/${fileIdOrSlug}/token`;

  const response = await ApiRequest(apiUrl, HttpMethod.POST, {
    expirationDate,
  });

  return response?.body;
};
