/* eslint-disable no-constant-condition */
import Spinner from "admin/src/ui/components/common/Spinner";
import NewAppButton from "admin/src/ui/components/common/newform/NewAppButton";
import NewAppForm from "admin/src/ui/components/common/newform/NewAppForm";
import NewAppLoadingSubmitButton from "admin/src/ui/components/common/newform/NewAppLoadingSubmitButton";
import NewAppModalBody, {
  ModalMinWidth,
  NewAppModalHandle,
} from "admin/src/ui/components/common/newmodal/NewAppModalBody";
import { SurveyPreview } from "admin/src/ui/components/surveyJS/preview/SurveyPreview";
import { FormikValues } from "formik";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { SessionView } from "shared/mappers/database/session/session";
import { SurveyModel } from "survey-core";
import * as Yup from "yup";

import { WorkflowInstancePayloadBase } from "admin/src/server/bll/workflow/instance/WorkflowInstanceRunner";
import { newApiRequest } from "admin/src/ui/api-callouts/utils";
import TemplateComponent from "admin/src/ui/components/TemplateComponent";
import NewAppTextAreaInput from "admin/src/ui/components/common/newform/NewAppTextAreaInput";
import { getDisplayNameHelper } from "admin/src/utils/helpers/profile/getDisplayNameHelper";
import { ENV } from "hub/src/appConstants/env";
import { Tab, Tabs } from "hub/src/components/common/tab";
import SubmissionInstanceTab, {
  ReviewsTabsType,
} from "hub/src/components/review/SubmissionInstanceTab";
import useHubSessionContext from "hub/src/hooks/useHubSessionContext";
import { HubRouteConfig } from "hub/src/routes/RouteConfigWrapper";
import { getSocietyId } from "hub/src/utils/getSocietyId";
import { getProfileId } from "hub/src/utils/sessionStorage/user";
import { getSocietyProfileReviewAssignmentIdContract } from "shared/api/contracts/society/societyId/profiles/profileId/review/assignments/reviewAssignmentId";
import { patchSocietyProfileReviewAssignmentRecuseContract } from "shared/api/contracts/society/societyId/profiles/profileId/review/assignments/reviewAssignmentId/recuse";
import { postSocietyReviewInstanceAssignmentVoteContract } from "shared/api/contracts/society/societyId/review/instance/reviewInstanceId/assignment/assignmentId/vote";
import { ReviewInstanceAssignmentView } from "shared/mappers/database/review/reviewInstanceAssignment";

const validationSchema = Yup.object().shape({
  reasonForRecuse: Yup.string().required("This field is required"),
});

export type HubReviewsVotingPageRouteParams = {
  reviewInstanceAssignmentId: number;
  reviewSessionId: number;
};

const ReviewsVotingPage = (
  routeConfig: HubRouteConfig<HubReviewsVotingPageRouteParams>,
) => {
  const session = useHubSessionContext();
  const history = useHistory();
  const recuseModalRef = useRef<NewAppModalHandle>(null);
  const { reviewInstanceAssignmentId, reviewSessionId } =
    routeConfig.routeParams!;
  const [tabs, setTabs] = useState<ReviewsTabsType[] | null>(null);
  const [selectedTab, setSelectedTab] = useState<string>("tab-0");
  const [reviewInstanceAssignment, setReviewInstanceAssignment] =
    useState<ReviewInstanceAssignmentView | null>(null);
  const [currentAssignmentIndexInSession, setCurrentAssignmentIndexInSession] =
    useState<number | null>(null);

  const totalAssignments =
    reviewInstanceAssignment?.session?.reviewInstanceAssignment;
  const firstReviewAssignment = currentAssignmentIndexInSession === 0;
  const lastReviewAssignment =
    currentAssignmentIndexInSession === totalAssignments?.length! - 1;

  const buildTabsArray = (
    response: ReviewInstanceAssignmentView,
  ): ReviewsTabsType[] => {
    const tabsArry: ReviewsTabsType[] = [];
    tabsArry.push({
      submissionInstance: response.reviewInstance!.submissionInstance!,
      profile: response.reviewInstance!.submissionInstance!.primaryProfile!,
      disclosureInstance: response.reviewInstance?.disclosureInstance,
    });

    if (
      response.reviewInstance?.submissionInstance?.workflowInstance
        ?.parentWorkflowInstance?.submissionInstance
    ) {
      tabsArry.push({
        submissionInstance:
          response.reviewInstance?.submissionInstance?.workflowInstance
            ?.parentWorkflowInstance?.submissionInstance,
        profile:
          response.reviewInstance?.submissionInstance?.workflowInstance
            ?.parentWorkflowInstance?.submissionInstance?.primaryProfile!,
      });
    }

    const childParticipants =
      response.reviewInstance?.submissionInstance?.childSubmissionParticipant ??
      [];

    for (const participant of childParticipants) {
      if (
        participant.showInReview ||
        participant.disclosureSubmissionInstance
      ) {
        tabsArry.push({
          submissionInstance: participant.showInReview
            ? {
                ...participant.submissionInstance!,
                primaryProfile: participant.profile,
                submissionDefinition:
                  response.reviewInstance?.submissionInstance
                    ?.submissionDefinition,
              }
            : undefined,
          disclosureInstance: tabsArry.find(
            (tab) =>
              tab.disclosureInstance?.id ===
              participant.disclosureSubmissionInstance?.id,
          )
            ? undefined
            : participant.disclosureSubmissionInstance,
          profile: participant.profile!,
          role: participant.roleText,
        });
      }
    }

    return tabsArry;
  };

  useEffect(() => {
    if (!reviewSessionId) return;
    newApiRequest(getSocietyProfileReviewAssignmentIdContract, {
      params: {
        societyId: getSocietyId(),
        profileId: session.profileId!,
        reviewAssignmentId: Number(reviewInstanceAssignmentId),
      },
    }).then((response) => {
      setReviewInstanceAssignment(response);
      setCurrentAssignmentIndexInSession(
        response.session?.reviewInstanceAssignment?.findIndex(
          (assignment) =>
            assignment.reviewInstanceAssignmentId ===
            response.reviewInstanceAssignmentId,
        ) ?? null,
      );
      setTabs(buildTabsArray(response));
    });
  }, [reviewSessionId, reviewInstanceAssignmentId]);

  const handleRecuse = async (formValues: FormikValues) => {
    newApiRequest(patchSocietyProfileReviewAssignmentRecuseContract, {
      params: {
        societyId: getSocietyId(),
        profileId: getProfileId(),
        reviewAssignmentId: Number(reviewInstanceAssignmentId),
      },
      body: {
        recusalReason: formValues.reasonForRecuse,
      },
    });
    if (!lastReviewAssignment) {
      handleNext();
    } else {
      history.push("/reviews");
    }
    recuseModalRef.current?.close();
  };

  const handleBack = () => {
    if (currentAssignmentIndexInSession === 0) return;
    history.push(
      `/reviews/${reviewSessionId}/assignment/${totalAssignments?.[currentAssignmentIndexInSession! - 1]?.reviewInstanceAssignmentId}`,
    );
  };

  const handleNext = () => {
    if (currentAssignmentIndexInSession === totalAssignments?.length! - 1)
      return;
    history.push(
      `/reviews/${reviewSessionId}/assignment/${totalAssignments?.[currentAssignmentIndexInSession! + 1]?.reviewInstanceAssignmentId}`,
    );
  };

  const renderSurveyPreview = (
    currentAssignment: ReviewInstanceAssignmentView,
  ) => {
    if (!currentAssignment) return null;
    return (
      <div>
        <SurveyPreview
          key={currentAssignmentIndexInSession}
          baseUrl={ENV.REACT_APP_PILLAR_API_URL}
          session={session as SessionView}
          completeText="Submit Review"
          onCompleteCallback={async (sender: SurveyModel) => {
            await newApiRequest(
              postSocietyReviewInstanceAssignmentVoteContract,
              {
                params: {
                  profileId: session.profileId!,
                  societyId: session.societyId!,
                  reviewInstanceId: sender.data.reviewInstanceId,
                  assignmentId: sender.data.assignmentId,
                },
                body: {
                  score: sender.data.score,
                  voteData: sender.data,
                  reviewInstanceVoteId: sender.data.reviewInstanceVoteId,
                },
              },
            );

            if (!lastReviewAssignment) {
              handleNext();
            } else {
              history.push("/reviews");
            }

            return {
              payload: sender.data as WorkflowInstancePayloadBase,
              userhubRedirect: lastReviewAssignment ? "/reviews" : undefined,
            };
          }}
          formDesign={
            reviewInstanceAssignment?.session?.scorecardForm?.formDesign!
          }
          formData={{
            reviewInstanceId:
              reviewInstanceAssignment?.reviewInstance?.reviewInstanceId,
            assignmentId: reviewInstanceAssignmentId,
            reviewInstanceVoteId: reviewInstanceAssignment?.vote![0]?.id,
            ...(reviewInstanceAssignment?.vote![0]?.voteData
              ? JSON.parse(
                  JSON.stringify(reviewInstanceAssignment.vote[0].voteData),
                )
              : {}),
          }}
          spinner={<Spinner />}
        />
      </div>
    );
  };

  useEffect(() => {
    renderSurveyPreview(reviewInstanceAssignment!);
  }, [reviewInstanceAssignment]);

  if (!reviewInstanceAssignment)
    return (
      <>
        <Spinner />
      </>
    );

  return (
    <>
      <NewAppModalBody
        minW={ModalMinWidth.Sm_500}
        ref={recuseModalRef}
        title="Recuse Review"
      >
        <NewAppForm
          className="pt-2"
          handleSubmit={handleRecuse}
          handleChanged={() => {
            null;
          }}
          yupValidation={validationSchema}
        >
          <NewAppTextAreaInput
            rows={3}
            name="reasonForRecuse"
            label="Reason for recuse"
            placeholder="Enter a reason"
          />
          <div className="pt-2 flex justify-around">
            <NewAppButton
              onClick={() => recuseModalRef?.current?.close()}
              className="button-regular-neutral-filled"
              title="Close"
              label="Close"
            />
            <NewAppLoadingSubmitButton
              title="Recuse"
              label="Recuse"
              data-testid="completeRecusalButton"
            />
          </div>
        </NewAppForm>
      </NewAppModalBody>
      <div className="p-5 h-full overflow-y-auto">
        <div className="grid grid-cols-7">
          <div className="mr-3 col-span-4 overflow-y-scroll">
            {tabs && (
              <Tabs
                selectedTab={selectedTab}
                setSelectedTab={(tab) => setSelectedTab(tab)}
              >
                {tabs.map((tab, tabIndex) => {
                  return (
                    <Tab
                      key={`tab-${tabIndex}`}
                      name={`tab-${tabIndex}`}
                      titleText={
                        <TemplateComponent
                          template={
                            reviewInstanceAssignment?.session?.tabsTemplate ??
                            "No Template Found"
                          }
                          data={{
                            name: getDisplayNameHelper(tab.profile) ?? "N/A",
                            role: tab.role,
                            reviewInstanceAssignment,
                            ...tab,
                          }}
                        />
                      }
                    >
                      <SubmissionInstanceTab
                        key={currentAssignmentIndexInSession}
                        reviewInstanceAssignment={reviewInstanceAssignment}
                        {...tab}
                      />
                    </Tab>
                  );
                })}
              </Tabs>
            )}
          </div>
          <div className="col-span-3">
            <div className="flex flex-col">
              <div className="mb-1 flex items-center justify-between">
                <NewAppButton
                  disabled={firstReviewAssignment}
                  onClick={handleBack}
                  className="px-3 button-review-voting-action"
                  title="Back"
                  data-testid="backButton"
                >
                  Back{" "}
                  {!firstReviewAssignment
                    ? ` to ${currentAssignmentIndexInSession!} of ${totalAssignments?.length}`
                    : ""}
                </NewAppButton>
                <NewAppButton
                  onClick={() => recuseModalRef.current?.open()}
                  className="px-3 bg-status-danger-tint"
                  title="Recuse"
                  label="Recuse"
                  data-testid="recuseButton"
                />
                <NewAppButton
                  disabled={lastReviewAssignment}
                  className="px-3 button-review-voting-action"
                  title="Skip"
                  data-testid="skipButton"
                  onClick={handleNext}
                >
                  Skip{" "}
                  {!lastReviewAssignment
                    ? ` to ${currentAssignmentIndexInSession! + 2} of ${totalAssignments?.length}`
                    : ""}
                </NewAppButton>
              </div>
            </div>
            {renderSurveyPreview(reviewInstanceAssignment)}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewsVotingPage;
