import { SocietyProfileReviewAssignmentSubmissionInstanceParamsInput } from "shared/api/types/society/[societyId]/profiles/[profileId]/review/assignments/[reviewAssignmentId]/submission/[submissionInstanceId]/preview";
import { PreviewView } from "shared/mappers/database/submission/preview/preview-instance";
import { BaseApiContract } from "admin/src/ui/api-callouts/utils";
import { HttpMethod } from "admin/src/ui/types/common/http";
export const getSocietyProfileReviewAssignmentSubmissionInstancePreviewContract: BaseApiContract<
  SocietyProfileReviewAssignmentSubmissionInstanceParamsInput,
  undefined,
  PreviewView
> = {
  method: HttpMethod.GET,
  apiRoute:
    "/api/v1/society/[societyId]/profiles/[profileId]/review/assignments/[reviewAssignmentId]/submission/[submissionInstanceId]/preview",
  paramsType: {} as SocietyProfileReviewAssignmentSubmissionInstanceParamsInput,
  responseType: {} as PreviewView,
};
