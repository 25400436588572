import { IonNote } from "@ionic/react";
import NewAppButton from "admin/src/ui/components/common/newform/NewAppButton";
import { AuthenticateUser, useApiMutation } from "hub/src/api";
import { AxiosError } from "axios";
import LoadingPage from "hub/src/components/common/LoadingPage";
import PageComponent from "hub/src/components/common/PageComponent";
import { SocietyLogo } from "hub/src/components/common/SocietyLogo";
import useDeviceInfo from "hub/src/hooks/useDeviceInfo";
import useHubSessionContext from "hub/src/hooks/useHubSessionContext";
import useLastPathName from "hub/src/hooks/useLastPathName";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { AuthError } from "hub/src/types/auth/error";
import { getSocietyId } from "hub/src/utils/getSocietyId";

const LoginPage = () => {
  const session = useHubSessionContext();
  const device = useDeviceInfo();

  const { path } = useLastPathName();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const authenticateUserMutation = useApiMutation(AuthenticateUser, {
    societyId: getSocietyId().toString(),
  });

  useEffect(() => {
    if (authenticateUserMutation.error) {
      const authError = authenticateUserMutation.error as AxiosError<AuthError>;
      if (authError?.response?.status === 401) {
        setErrorMsg(
          "Check your email and password and ensure they are correct.",
        );
      } else {
        setErrorMsg("Something went wrong. Please try again later.");
      }
    }
  }, [authenticateUserMutation.error]);

  useEffect(() => {
    if (
      authenticateUserMutation.data &&
      authenticateUserMutation.data.data.body!.societyUser
    ) {
      if (!session.societyUserId && authenticateUserMutation.data.data.body) {
        session.setSession(authenticateUserMutation.data.data.body!);
      }
      history.replace(path || "/overview");
    }
  }, [authenticateUserMutation.data]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    authenticateUserMutation.mutate({
      urlParamaterizedAuth: {
        username: email,
        password: password,
      },
    });
  };
  if (authenticateUserMutation.isLoading) {
    return <LoadingPage />;
  }

  return (
    <PageComponent>
      <PageComponent.Content className="container-light h-full w-full">
        <div
          className="h-full w-full max-w-1/4 min-w-9xl m-auto
          flex flex-col justify-center items-center pl-3 pr-3
          phone:min-w-full phone:max-w-none"
        >
          {/* TODO: This is a placeholder for the society logo, which we currently
        only have access to after authentication. We will need to either fetch
        the society info prior to auth or package the logos with the build
        itself per-society. If we opt not to package with the build, we may not
        be able to include it in the splash screen, but can still include it
        here. Once we get this out of placeholder, these styles should be in common
        or component tokens. The only inline should be the layout utility classes. */}
          {session.society?.societySettingsPublic?.societyLogoLoginUrl ? (
            <SocietyLogo className="w-[100px] h-[100px] mb-2 !border-society-400" />
          ) : (
            <div className="w-12.5 h-12.5 mb-7 rounded-full bg-primary flex justify-center items-center">
              <span className="text-5xl text-extrabold text-neutral-light">
                {session.society?.abbreviation}
              </span>
            </div>
          )}

          <h2 className="mb-4.5">Log In</h2>
          {/* TODO: Replace this with NewAppForm */}
          <form
            id="login-form"
            onSubmit={handleSubmit}
            className="flex flex-col w-full"
          >
            <label htmlFor="email">Email address</label>
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              data-testid={"login-email"}
            />
            <label htmlFor="password" className="mt-3">
              Password
            </label>
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              data-testid={"login-password"}
            />
            {authenticateUserMutation.error && (
              <IonNote className="text-danger-small">{errorMsg}</IonNote>
            )}
            <NewAppButton
              type="submit"
              className="button-regular-general-filled mt-4"
              testid="login-button"
            >
              Log In
            </NewAppButton>

            {device.isWeb && (
              <NewAppButton className="button-regular-general-unfilled mt-4 group">
                <Link to="/account/new">
                  {"Don't have an account? "}
                  <span data-testid="signup-link" className="font-bold">
                    Sign Up!
                  </span>
                </Link>
              </NewAppButton>
            )}

            <NewAppButton className="button-regular-general-unfilled mt-4 group">
              <Link to="/account/recover">
                {"Forgot Login? "}

                <span className="font-bold">Click to recover!</span>
              </Link>
            </NewAppButton>
          </form>
        </div>
      </PageComponent.Content>
    </PageComponent>
  );
};

export default LoginPage;
