import { IonSpinner } from "@ionic/react";

const LoadingPage = () => {
  return (
    <div className="flex justify-center h-full w-full">
      <IonSpinner name="dots" className="self-center"></IonSpinner>
    </div>
  );
};

export default LoadingPage;
