import { useCustomRouter } from "@parthenon-management/pillar-admin/src/ui/context/CustomRouterContext";
import React from "react";
import { useParams } from "react-router";
import { RouteParams } from "shared/api/types/society/[societyId]/app-route-config";
import { AppRouteConfigView } from "shared/mappers/database/app-route/app-route-config/app-route-config";

export interface HubRouteConfig<RP = RouteParams>
  extends Partial<Omit<AppRouteConfigView, "routeParams">> {
  routeParams?: RP;
}

type RouteConfigWrapperProps = {
  children: React.ReactNode;
  routeConfig?: Partial<AppRouteConfigView>;
};

export const RouteConfigWrapper = ({
  children,
  routeConfig,
}: RouteConfigWrapperProps) => {
  const dynamicParams = useParams<{ [key: string]: string }>();
  const router = useCustomRouter();
  const parsedParams: Record<string, string | number | boolean> = {};
  Object.keys(dynamicParams).forEach((key) => {
    const value = dynamicParams[key];
    if (!isNaN(Number(value))) {
      parsedParams[key] = Number(value);
    } else if (value === "true" || value === "false") {
      parsedParams[key] = value === "true";
    } else {
      parsedParams[key] = value;
    }
  });

  const mergedRouteParams = {
    ...routeConfig?.routeParams,
    ...parsedParams,
    ...(router.state || {}),
  } as RouteParams;
  return React.cloneElement(children as React.ReactElement<HubRouteConfig>, {
    ...routeConfig,
    routeParams: mergedRouteParams,
  });
};

export default RouteConfigWrapper;
