/* eslint-disable no-useless-catch */

import { InvoiceItemView } from "shared/mappers/database/accounting/invoice/invoice-item";
import { apiClient, buildApiClient } from "hub/src/api";
import { PillarPDF } from "hub/src/types/PDF";
import { getSocietyId } from "hub/src/utils/getSocietyId";
import { getProfileId } from "hub/src/utils/sessionStorage/user";

export const deleteInvoiceItemById = async (invoiceItemId: number) => {
  buildApiClient();
  const response = await apiClient!.delete(
    `/api/v1/society/${getSocietyId()}/accounting/invoices/profile/${getProfileId()}/delete-invoice-item?invoiceItemId=${invoiceItemId}`,
  );

  return response!.data;
};

export const addInvoiceItem = async (invoiceItem: InvoiceItemView) => {
  try {
    const response = await apiClient!.post(
      `/api/v1/society/${getSocietyId()}/accounting/invoices/profile/${getProfileId()}/add-invoice-item`,
      invoiceItem,
    );

    return response!.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getInvoicePDF = async (
  invoiceId: number,
  profileId: number,
): Promise<PillarPDF> => {
  try {
    buildApiClient();
    const response = await apiClient!.get(
      `/api/v1/society/${getSocietyId()}/profiles/${profileId}/accounting/invoice/${invoiceId}/generate-pdf`,
    );

    const { body } = response!.data;

    return body;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
