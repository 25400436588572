import {
  QuestionDropdownModel,
  QuestionPanelDynamicModel,
  SurveyModel,
} from "survey-core";

import { FunctionFactory } from "survey-core";
import { IRoleItemValue } from "./ProfileSearchMultipleQuestion";

export type ProfileSearchQuestionValues = {
  role: string;
  position: string;
  coauthorBlock?: string;
  profilesearch: {
    profile_id: number;
    display_name: string;
    affiliation: string;
  };
};

export const profileSearchValidationExpressions = async () => {
  FunctionFactory.Instance.register(
    "checkRoleExists",
    function checkRoleExists(this: {
      survey: SurveyModel;
      question: QuestionDropdownModel;
    }): boolean {
      return Boolean(this.question.value);
    },
  );

  FunctionFactory.Instance.register(
    "checkForDuplicateProfileIds",
    function checkForDuplicateProfileIds(this: {
      survey: SurveyModel;
      question: QuestionDropdownModel;
    }): boolean {
      const profileSearch = this.question.value;

      if (profileSearch === undefined) {
        return true;
      }
      const values = this.question.parentQuestion
        ?.value as ProfileSearchQuestionValues[];
      if (!values) return true;

      const roleCount: { [role: string]: number } = {};

      values.forEach((value) => {
        if (value.profilesearch?.profile_id === profileSearch.profile_id) {
          roleCount[value.role] = (roleCount[value.role] || 0) + 1;
        }
      });
      const hasDuplicateRole = Object.values(roleCount).some(
        (count: number) => count > 1,
      );
      return !hasDuplicateRole;
    },
  );

  FunctionFactory.Instance.register(
    "checkForDuplicatePositions",
    function checkForDuplicatePositions(this: {
      survey: SurveyModel;
      question: QuestionDropdownModel;
    }): boolean {
      const multipleProfileSearchQuestion = this.question.parentQuestion;
      const values =
        multipleProfileSearchQuestion.value as ProfileSearchQuestionValues[];
      const positions = values
        .map((value: ProfileSearchQuestionValues) => value.position)
        .filter((position: string) => position !== undefined);

      return new Set(positions).size === positions.length;
    },
  );

  FunctionFactory.Instance.register(
    "checkProfileIdSet",
    function checkProfileIdSet(this: {
      survey: SurveyModel;
      question: QuestionDropdownModel;
    }): boolean {
      return this.question.value !== "none";
    },
  );

  FunctionFactory.Instance.register(
    "checkRequiredRolesMaximum",
    function checkRequiredRolesMaximum(this: {
      survey: SurveyModel;
      question: QuestionDropdownModel;
    }): boolean {
      if (!this.question.value) return true;
      const parentQuestion = this.question.parentQuestion
        .parent as unknown as QuestionPanelDynamicModel;
      const roles = parentQuestion.getPropertyValue("role");
      const roleCounts = parentQuestion.value.reduce(
        (counts: { [key: string]: number }, item: { role: string }) => {
          if (item.role) counts[item.role] = (counts[item.role] || 0) + 1;
          return counts;
        },
        {},
      );
      return roles.every((role: IRoleItemValue) => {
        if (!role.max) return true;
        return role.max! >= (roleCounts[role.value] || 0);
      });
    },
  );

  FunctionFactory.Instance.register(
    "checkForCorrectPositions",
    function checkForCorrectPositions(this: {
      survey: SurveyModel;
      question: QuestionDropdownModel;
    }): boolean {
      const values = this.question.parentQuestion
        .value as ProfileSearchQuestionValues[];
      const positions = values
        .map((value: { position: string }) => Number(value.position))
        .filter((position: number) => !isNaN(position))
        .sort((a: number, b: number) => a - b);
      return positions.every(
        (position: number, i: number, arr: number[]) =>
          position === arr[0] || position === i + 1,
      );
    },
  );
};
