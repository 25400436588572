/* eslint-disable @typescript-eslint/no-explicit-any */

import ApiRequest from "admin/src/ui/api-callouts/api-request-builder";
import { buildUrl } from "admin/src/ui/api-callouts/urlBuilder";
import { ApiResponse } from "admin/src/ui/types/common/api-response";
import { PaginationRequest } from "admin/src/ui/types/common/general-types";
import { HttpMethod } from "admin/src/ui/types/common/http";
import { FiltersRequest } from "admin/src/utils/helpers/filter-where-clause";
export interface BaseApiContract<
  ParamsType = any,
  BodyType = any,
  ResponseType = any,
> {
  method: HttpMethod;
  apiRoute: string;
  paramsType?: ParamsType;
  bodyType?: BodyType;
  responseType: ResponseType;
}

// Utility type to infer params type from a contract
export type ExtractContractInputs<T> = T extends {
  paramsType?: infer P;
  bodyType?: infer B;
}
  ? { params?: P; body?: B }
  : never;

// The apiRequest function adjusted to use the contract for typing
export const newApiRequest = async <T extends BaseApiContract>(
  contract: T,
  inputs?: ExtractContractInputs<T>,
  filters?: FiltersRequest,
  pagination?: PaginationRequest,
  customHeaders?: HeadersInit,
  customParser?: (Response: Response) => ApiResponse["body"],
  returnRawResponse = false,
): Promise<T["responseType"]> => {
  const params = inputs?.params ? inputs.params : {};

  //Do function to replace the dynamic values in the apiRoute
  const url = buildUrl(contract.apiRoute, params);

  //Actually do the API request and return the response
  const response = await ApiRequest(
    url,
    contract.method,
    inputs?.body,
    customHeaders,
    customParser,
    filters,
    pagination,
    returnRawResponse,
  );
  return response?.body as T["responseType"];
};
