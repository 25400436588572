import { SocietyView } from "shared/mappers/database/society/society";
import { getSurveyFormQuestionNames } from "admin/src/ui/components/surveyJS/utils/getSurveyFormQuestionNames";
import { Question, Serializer } from "survey-core";

export type GenericQuestionPropertiesProps = {
  society: SocietyView;
};
export const genericQuestionProperties = ({
  society,
}: GenericQuestionPropertiesProps) => {
  Serializer.addProperty("question", {
    name: "willUpdateProfile",
    displayName: "Should this also update the user's profile?",
    type: "boolean",
    category: "Profile Properties",
  });
  Serializer.addProperty("question", {
    name: "profileField",
    displayName: "Which field should this profile update?",
    type: "dropdown",
    choices: society?.profileForm
      ? getSurveyFormQuestionNames(society.profileForm.formDesign).map(
          (name) => ({
            value: name,
            text: name,
          }),
        )
      : [],
    category: "Profile Properties",
    dependsOn: ["willUpdateProfile"],
    visibleIf: function (obj: any) {
      return obj.willUpdateProfile;
    },
  });
  Serializer.addProperty("question", {
    name: "reportType",
    displayName: "Does this field show up in reports, if so which ones?",
    type: "multiplevalues",
    choices: ["none", "demographics", "contact", "address", "other"],
    category: "Profile Properties",
    dependsOn: ["willUpdateProfile"],
    visibleIf: function (obj: any) {
      return obj.willUpdateProfile;
    },
  });
  Serializer.addProperty("question", {
    name: "isReportedInScore",
    displayName: "Is reported in on scorecard",
    type: "boolean",
    category: "Scorecard Properties",
    visibleIf: function (obj: Question) {
      return obj.getType() == "rating" || obj.getType() == "boolean";
    },
  });
};
