import _debounce from "lodash/debounce";
import { DateTime } from "luxon";
import {
  ComponentCollection,
  ICustomQuestionTypeConfiguration,
  Question,
  Serializer,
  SurveyModel,
} from "survey-core";
import { SurveyCreatorModel } from "survey-creator-core";
import { SessionView } from "shared/mappers/database/session/session";
import { checkUsernameExistsCallout } from "admin/src/ui/api-callouts/identity";
import { ContactInformationUI } from "admin/src/ui/types/profile/profile-address-contact-information";
//value to limit the number of profile questions, only used to optimize the loop in onValueChanged
const debouncedCheckUsername = _debounce(
  async (
    societyId: number,
    value: string,
    question: Question,
    callback: (question: Question, isUnique: boolean) => void,
  ) => {
    const result = await checkUsernameExistsCallout({
      username: value,
      societyId,
    });

    callback(question, !result);
  },
  1000,
);
export const custom_question_login_details = "login_details";
export const login_details_question = async (
  session: SessionView,
  creator?: SurveyCreatorModel,
) => {
  const questionTitle = "Login Details";

  creator?.onQuestionAdded.add((sender: SurveyCreatorModel, options) => {
    if (options.question?.getType() == custom_question_login_details) {
      options.question.name = custom_question_login_details;
    }
  });
  const login_details_object: ICustomQuestionTypeConfiguration & {
    handlePasswordVerification: (question: Question, password: string) => void;
    handlePasswordConfirmVerification: (
      question: Question,
      password: string,
    ) => void;
    handleUsernameExists: (question: Question, isUnique: boolean) => void;
  } = {
    name: custom_question_login_details,
    title: questionTitle,
    elementsJSON: [
      {
        type: "html",
        name: "recovery_link",
        html: "",
        visible: false,
      },
      {
        type: "text",
        name: "user_name",
        title: "E-Mail",
        inputType: "text",
        textUpdateMode: "onTyping",
        isRequired: !session.adminMode && !session.societyUser,
      },
      {
        type: "text",
        name: "old_password",
        title: "Old Password",
        inputType: "password",
        visible:
          session.societyUserId !== undefined &&
          !session.adminMode &&
          session.iat! < DateTime.now().minus({ days: 1 }).toSeconds(),
      },
      {
        type: "text",
        name: "password",
        autocomplete: "new-password",
        title: "Password",
        isRequired: session.societyUser === undefined && !session.societyUser,
        textUpdateMode: "onTyping",
        inputType: "password",
      },
      {
        type: "text",
        name: "password_confirm",
        startWithNewLine: false,
        title: "Confirm Password",
        inputType: "password",
        textUpdateMode: "onTyping",
        isRequired: session.profile === undefined,
        autocomplete: "new-password",
      },
      {
        type: "html",
        name: "password_complexity_html",
        html: "",
        visible: false,
      },
      {
        type: "html",
        name: "password_confirm_html",
        html: "",
        startWithNewLine: false,
        visible: false,
      },
    ],
    handlePasswordConfirmVerification(question: Question, password: string) {
      const password_confirm = question.contentPanel.getQuestionByName(
        "password_confirm_html",
      );
      if (
        password !== question.contentPanel.getQuestionByName("password").value
      ) {
        const errorMessageHTML = `
        <p style="font-size: 16px; padding: 10px; border-radius: 4px; font-weight: bold; color: red;">
       Passwords do not match.</p>`;
        password_confirm.visible = true;
        password_confirm.html = errorMessageHTML;
      } else {
        password_confirm.visible = false;
        password_confirm.html = "";
      }
    },
    handlePasswordVerification(question: Question, password: string) {
      const password_complexity = question.contentPanel.getQuestionByName(
        "password_complexity_html",
      );
      const password_confirm =
        question.contentPanel.getQuestionByName("password_confirm");
      const conditions = [
        password.length < 8 &&
          "Your password must be at least 8 characters long.",
        !/(?=.*[A-Z])/.test(password) &&
          "Your password must contain an uppercase letter.",
        !/(?=.*[a-z])/.test(password) &&
          "Your password must contain a lowercase letter.",
        !/(?=.*\d)/.test(password) && "Your password must contain a number.",
        !/[!@#$%^&*(),.?":{}|<>_]/.test(password) &&
          "Your password must contain a symbol.",
      ];

      const errorMessageHTML = `
        <div >
          ${conditions
            .filter(Boolean)
            .map(
              (message) =>
                `<p style="color: red !important; font-size : 13px; font-weight : bold;">${message}</p>`,
            )
            .join("")}
        </div>`;
      password_complexity.visible = true;
      password_complexity.html = errorMessageHTML;
      if (conditions.filter(Boolean).length > 0) {
        password_confirm.value = "";
        password_confirm.readOnly = true;
      } else {
        password_complexity.visible = false;
        password_confirm.readOnly = false;
      }
    },
    handleUsernameExists(question: Question, isUnique: boolean) {
      const survey = question.getSurvey() as SurveyModel;
      survey.showNavigationButtons = isUnique;
      question.contentPanel.getQuestionByName("recovery_link").visible =
        !isUnique;
      survey.getAllQuestions().forEach((q: Question) => {
        if (q.name !== custom_question_login_details) {
          q.readOnly = !isUnique;
        }
        question.contentPanel.getQuestionByName("password").readOnly =
          !isUnique;
        question.contentPanel.getQuestionByName("password_confirm").readOnly =
          !isUnique;
      });
      if (isUnique) {
        question.contentPanel.getQuestionByName("recovery_link").html = "";
      } else {
        question.contentPanel.getQuestionByName("recovery_link").html = `
        <p style="background-color: #FCE9EE; color: #E60A3E; font-size: 13px; padding: 10px; border-radius: 4px; font-weight: bold;">
          This username is not available. If you believe you may already have a profile, please use this 
          <a href='${session.society?.societySettingsPublic?.userHubUrl}/account/recover' style="color: blue; text-decoration: underline;">link</a> 
          to recover your account.
        </p>
      `;
      }
    },
    onInit() {
      //Override titleLocation property attributes for "shippingaddress" class by making it invisible in property grid and change its default value
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onCreated(question: Question) {
      // throw new Error("Function not implemented.");
    },
    onLoaded(question: Question) {
      Serializer.getProperty(custom_question_login_details, "name").readOnly =
        true;
      Serializer.addProperty(custom_question_login_details, {
        name: "changePasswordTitle",
        type: "text",
        default: "Change Password",
        category: "general",
      });

      // Serializer.getProperty(custom_question_login_details, "name").visible =
      //   false;
      // Serializer.getProperty(custom_question_login_details, "title").readOnly =
      //   true;
      Serializer.getProperty(custom_question_login_details, "visible").visible =
        false;
      Serializer.getProperty(
        custom_question_login_details,
        "isRequired",
      ).visible = false;
      Serializer.getProperty(
        custom_question_login_details,
        "readOnly",
      ).visible = false;

      Serializer.getProperty(
        custom_question_login_details,
        "visibleIf",
      ).visible = false;

      Serializer.getProperty(
        custom_question_login_details,
        "enableIf",
      ).visible = false;
      Serializer.getProperty(
        custom_question_login_details,
        "requiredIf",
      ).visible = false;
      Serializer.getProperty(
        custom_question_login_details,
        "clearIfInvisible",
      ).visible = false;
      Serializer.getProperty(
        custom_question_login_details,
        "defaultValueExpression",
      ).visible = false;
      Serializer.getProperty(
        custom_question_login_details,
        "defaultValue",
      ).visible = false;
      Serializer.getProperty(
        custom_question_login_details,
        "startWithNewLine",
      ).visible = false;
      Serializer.getProperty(
        custom_question_login_details,
        "valueName",
      ).visible = false;
      Serializer.getProperty(
        custom_question_login_details,
        "useDisplayValuesInTitle",
      ).visible = false;
      Serializer.getProperty(
        custom_question_login_details,
        "correctAnswer",
      ).visible = false;

      Serializer.getProperty(
        custom_question_login_details,
        "requiredErrorText",
      ).visible = false;

      Serializer.getProperty(
        custom_question_login_details,
        "validators",
      ).visible = false;
      if (session.profile && session.profile.institution) {
        question.visible = false;
        return;
      }
      if (session.profile) {
        question.title = Serializer.getProperty(
          custom_question_login_details,
          "changePasswordTitle",
        ).value;
      }
      // throw new Error("Function not implemented.");
    },
    onAfterRender: function (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      question: Question,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      htmlElement: any,
    ): void {
      // throw new Error("Function not implemented.");
    },
    onAfterRenderContentElement: function (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      question: Question,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      element: Question,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
      htmlElement: any,
    ): void {
      //  throw new Error("Function not implemented.");
    },
    onPropertyChanged: function (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      question: Question,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      propertyName: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
      newValue: any,
    ): void {
      //  throw new Error("Function not implemented.");
    },
    onValueChanging: function (
      question: Question,
      name: string,
      newValue: any,
    ) {
      if (name === "password") {
        this.handlePasswordVerification(question, newValue);
      }
      if (name === "password_confirm") {
        this.handlePasswordConfirmVerification(question, newValue);
      }

      if (name === "user_name") {
        debouncedCheckUsername(
          session.societyId!,
          newValue,
          question,
          this.handleUsernameExists,
        );
        //the convention we established is to have the multiple address questions named appended with their arbitrary index/sequence number

        //Get all survey questions
        const allProfileAddressContactInformationQuestions = (
          question.survey as SurveyModel
        )
          .getAllQuestions()
          .filter((q) => q.getType() === "profileaddresscontactinformation");
        //Loop through all the profile address contact information questions
        for (const profileAddressContactInformationQuestion of allProfileAddressContactInformationQuestions) {
          if (
            profileAddressContactInformationQuestion.value.email === null ||
            profileAddressContactInformationQuestion.value.email ===
              undefined ||
            profileAddressContactInformationQuestion.value.email ===
              question.value.user_name
          ) {
            profileAddressContactInformationQuestion.updateValueFromSurvey({
              ...profileAddressContactInformationQuestion.value,
              email: newValue,
            });
            const surveyModel =
              profileAddressContactInformationQuestion.getSurvey() as unknown as SurveyModel;
            const surveyData = surveyModel.getSurveyData();
            //This is really gross and is being copy pasted from question.ts instead of decoupled out
            //In a hurry and i hate that i'm doing this.
            let contactInformationData: ContactInformationUI[] =
              surveyData.getValue("contactInformation");
            contactInformationData = contactInformationData ?? [];
            let singleQuestionContactInformationData:
              | ContactInformationUI
              | { question_name: string };
            if (
              !contactInformationData.find(
                (data) =>
                  data.question_name ===
                  profileAddressContactInformationQuestion.name,
              )
            ) {
              singleQuestionContactInformationData = {
                question_name: profileAddressContactInformationQuestion.name,
              };
              contactInformationData.push(singleQuestionContactInformationData);
            }
            singleQuestionContactInformationData = contactInformationData.find(
              (data) =>
                data.question_name ===
                profileAddressContactInformationQuestion.name,
            ) ?? {
              question_name: "ERROR",
            };
            const questionValues =
              profileAddressContactInformationQuestion.contentPanel.getValue();
            singleQuestionContactInformationData.email = questionValues.email;
            singleQuestionContactInformationData.phone = questionValues.phone;
            singleQuestionContactInformationData.fax = questionValues.fax;
            singleQuestionContactInformationData.phone = questionValues.phone;
            singleQuestionContactInformationData.use = questionValues.use_id;
            singleQuestionContactInformationData.type = questionValues.type_id;
            if (questionValues.contact_information_id) {
              singleQuestionContactInformationData.id =
                questionValues.contact_information_id;
            }
            surveyData.setValue(
              "contactInformation",
              contactInformationData.filter(
                (data) => Object.values(data).filter((v) => v).length > 3,
              ),
              false,
              false,
            );
          }
        }
      }
      return newValue;
      // throw new Error("Function not implemented.");
    },
    onItemValuePropertyChanged: function (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      question: Question,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
      options: any,
    ): void {
      // throw new Error("Function not implemented.");
    },
  };
  if (
    !ComponentCollection.Instance.getCustomQuestionByName(
      custom_question_login_details,
    )
  ) {
    ComponentCollection.Instance.add(login_details_object);
  }
};
