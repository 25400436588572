import {
  PostSocietySubmissionReviewVoteParamsInput,
  PostSocietySubmissionReviewVoteBodyInput,
} from "shared/api/types/society/[societyId]/review/instance/[reviewInstanceId]/assignment/[assignmentId]/vote";
import { ReviewInstanceVoteView } from "shared/mappers/database/submission/review/review-instance-vote";
import { BaseApiContract } from "admin/src/ui/api-callouts/utils";
import { HttpMethod } from "admin/src/ui/types/common/http";
export const postSocietyReviewInstanceAssignmentVoteContract: BaseApiContract<
  PostSocietySubmissionReviewVoteParamsInput,
  PostSocietySubmissionReviewVoteBodyInput,
  ReviewInstanceVoteView
> = {
  method: HttpMethod.POST,
  apiRoute:
    "/api/v1/society/[societyId]/review/instance/[reviewInstanceId]/assignment/[assignmentId]/vote",
  paramsType: {} as PostSocietySubmissionReviewVoteParamsInput,
  bodyType: {} as PostSocietySubmissionReviewVoteBodyInput,
  responseType: {} as ReviewInstanceVoteView,
};
