import OrderSummary from "hub/src/components/checkout/OrderSummary";
import CheckoutInformationFormHOC from "hub/src/components/checkout/CheckoutInformationForm";
import PageComponent from "hub/src/components/common/PageComponent";
import { HubRouteConfig } from "hub/src/routes/RouteConfigWrapper";
export type HubCheckoutPageRouteParams = {
  invoiceId: number;
};
const CheckoutPage = (
  routeConfig: HubRouteConfig<HubCheckoutPageRouteParams>,
) => {
  const { invoiceId } = routeConfig.routeParams!;
  return (
    <PageComponent>
      <PageComponent.Header>Payment</PageComponent.Header>
      <PageComponent.Content>
        <div className="flex h-full md:flex-col md:bg-neutral-light">
          <OrderSummary
            invoiceId={Number(invoiceId)}
            className="h-full w-1/3 min-w-8xl overflow-y-auto md:w-full md:overflow-y-visible"
          />
          <CheckoutInformationFormHOC className="overflow-y-auto md:w-full md:overflow-y-visible" />
        </div>
      </PageComponent.Content>
    </PageComponent>
  );
};

export default CheckoutPage;
