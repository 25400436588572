import { useEffect, useState } from "react";
import { Capacitor } from "@capacitor/core";

type DeviceType = "android" | "ios" | "web";

interface DeviceInfo {
  isNativeApp: boolean;
  isIOS: boolean;
  isAndroid: boolean;
  isWeb: boolean;
}

const useDeviceInfo = (): DeviceInfo => {
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>({
    isNativeApp: false,
    isIOS: false,
    isAndroid: false,
    isWeb: false,
  });

  useEffect(() => {
    const isNativeApp = Capacitor.isNativePlatform();
    const device = Capacitor.getPlatform() as DeviceType;

    setDeviceInfo({
      isNativeApp: isNativeApp,
      isIOS: device === "ios",
      isAndroid: device === "android",
      isWeb: device === "web",
    });
  }, []);

  return deviceInfo;
};

export default useDeviceInfo;
