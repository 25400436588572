import { useState, useEffect } from "react";

type Props = {
  initialSeconds: number;
  callback: () => void;
};

export const useSecondsTimerWithCallback = ({
  initialSeconds,
  callback,
}: Props) => {
  const [time, setTime] = useState<number>(initialSeconds);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime === 0) {
          clearInterval(countdownInterval);
          callback();
          return prevTime;
        } else {
          return prevTime - 1;
        }
      });
    }, 1000);

    return () => clearInterval(countdownInterval);
  }, [initialSeconds, callback]);

  return { time };
};
