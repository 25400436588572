import { SocietyProfileSubmissionDefintionSubmissionInstanceParamsInput } from "shared/api/types/society/[societyId]/profiles/[profileId]/submission/[submissionDefinitionId]/instance/[submissionInstanceId]";
import { SocietyWorkflowBodyInput } from "shared/api/types/society/[societyId]/workflow/[workflowId]/instance";
import { BaseApiContract } from "admin/src/ui/api-callouts/utils";
import { HttpMethod } from "admin/src/ui/types/common/http";
export const postSocietyProfileDefinitionSubmissionInstanceAutosaveContract: BaseApiContract<
  SocietyProfileSubmissionDefintionSubmissionInstanceParamsInput,
  SocietyWorkflowBodyInput,
  void
> = {
  method: HttpMethod.POST,
  apiRoute:
    "/api/v1/society/[societyId]/profiles/[profileId]/submission/[submissionDefinitionId]/instance/[submissionInstanceId]/autosave",
  paramsType:
    {} as SocietyProfileSubmissionDefintionSubmissionInstanceParamsInput,
  bodyType: {} as SocietyWorkflowBodyInput,
  responseType: undefined as unknown as void,
};
