//chatgpt generated to iterate over the form and return the question names.
export const getSurveyFormQuestionNames = (json: any): string[] => {
  const questionTitles = [];

  if (json && typeof json === "object") {
    if (Array.isArray(json)) {
      for (const item of json) {
        questionTitles.push(...getSurveyFormQuestionNames(item));
      }
    } else {
      if (!json.elements) {
        if (json.name) {
          questionTitles.push(json.name);
        }
        for (const key in json) {
          questionTitles.push(...getSurveyFormQuestionNames(json[key]));
        }
      } else {
        questionTitles.push(...getSurveyFormQuestionNames(json.elements));
      }
    }
  }

  return questionTitles;
};
