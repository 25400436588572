interface SpinnerProps {
  spinnerSize?: SpinnerSize;
}

export enum SpinnerSize {
  Small = "small",
  Medium = "medium",
  Large = "large",
}

export const Spinner = ({ spinnerSize = SpinnerSize.Medium }: SpinnerProps) => {
  return (
    <div
      className={
        "flex justify-center overflow-hidden " +
        (spinnerSize === SpinnerSize.Small ? "m-0" : "m-5")
      }
    >
      <div
        className={
          "inline-block animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite] " +
          (spinnerSize === SpinnerSize.Large
            ? "w-12 h-12"
            : spinnerSize === SpinnerSize.Small
              ? "w-2.5 h-2.5"
              : "w-8 h-8")
        }
        role="status"
      >
        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
          Loading...
        </span>
      </div>
    </div>
  );
};
