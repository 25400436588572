import React, { ComponentPropsWithoutRef } from "react";
import { Tab, TabProps } from "./Tab";

type TabsProps = ComponentPropsWithoutRef<"div"> & {
  children: React.ReactElement<TabProps>[] | React.ReactElement<TabProps>;
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
};

export const Tabs = ({
  children,
  selectedTab,
  setSelectedTab,
  className,
  ...props
}: TabsProps) => {
  const beforeFilter = React.Children.toArray(children);
  const filteredChildren = beforeFilter.filter(
    (child): child is React.ReactElement<TabProps> =>
      React.isValidElement(child) &&
      child.type === Tab &&
      String(child.props.name).toLocaleLowerCase() ===
        selectedTab.toLocaleLowerCase(),
  );

  const tabs: TabProps[] = beforeFilter
    .map((child) => {
      if (React.isValidElement(child) && child.type === Tab) {
        return {
          name: child.props.name,
          ...(child.props.titleText && {
            titleText: child.props.titleText,
          }),
        };
      }
    })
    .filter((tab) => tab !== undefined);

  return (
    <div className={`flex flex-col ${className ?? ""}`} {...props}>
      <div className="tabs-container flex justify-between" id="menu">
        {tabs.map((tab) => {
          return (
            <div
              key={tab.name}
              onClick={() => setSelectedTab(tab.name)}
              className={`grow h-full ${tab.className ?? ""}
                ${
                  tab.name.toLocaleLowerCase() ===
                  selectedTab.toLocaleLowerCase()
                    ? "tabs-tab-selected"
                    : "tabs-tab-unselected"
                }
              `}
            >
              {tab.titleText ?? tab.name}
            </div>
          );
        })}
      </div>
      <div id="tabContainer">{filteredChildren ?? children}</div>
    </div>
  );
};
