/* eslint-disable @typescript-eslint/no-explicit-any */
import { removeHTMLTags } from "admin/src/utils/helpers/removeHTMLTags";
import React, { useState } from "react";
import {
  ElementFactory,
  Question,
  QuestionTextModel,
  Serializer,
  settings,
} from "survey-core";
import { SurveyCreator } from "survey-creator-react";
import { ReactQuestionFactory } from "survey-react-ui";

export const CUSTOM_TYPE_REACT_TEXTBOX = "react_textbox";

export enum CountPosition {
  LEFT = "left",
  RIGHT = "right",
  HIDDEN = "hidden",
}

export class ReactTextboxModel extends QuestionTextModel {
  getType() {
    return CUSTOM_TYPE_REACT_TEXTBOX;
  }
}

(settings as { customIcons: { [index: string]: string } }).customIcons[
  "icon-" + CUSTOM_TYPE_REACT_TEXTBOX
] = "icon-text";

export type ReactTextboxComponentInputProps = {
  creator: SurveyCreator;
  isDisplayMode: boolean;
  question: Question;
};

export const ReactTextboxComponent = (
  props: ReactTextboxComponentInputProps,
) => {
  const [questionValue, setQuestionValue] = useState(
    props.question.value || "",
  );
  const {
    placeholder_text,
    character_limit,
    word_limit,
    count_position,
    textbox_rows,
    textbox_resize,
  } = props.question;

  const handleChange = (value: any) => {
    let newValue = value[props.question.name] || "";
    if (character_limit && newValue.length >= character_limit) {
      newValue = newValue.slice(0, character_limit);
      setQuestionValue(newValue);
      props.question.value = newValue;
      return;
    }
    if (word_limit) {
      const words = newValue.split(/\s+/).filter(Boolean);
      if (words.length >= word_limit) {
        newValue = words.slice(0, word_limit).join(" ");
        setQuestionValue(newValue);
        props.question.value = newValue;
        return;
      }
    }
    setQuestionValue(newValue);
    props.question.value = newValue;
  };

  return (
    <div>
      <div className="flex flex-col">
        <textarea
          className={`border-none bg-primary-100 rounded-md p-1.5 ${
            textbox_resize ? "resize" : "resize-none"
          }`}
          value={props.question.value || questionValue}
          placeholder={removeHTMLTags(placeholder_text) ?? ""}
          rows={textbox_rows}
          onChange={(e) => {
            handleChange({ [props.question.name]: e.target.value });
          }}
        />
      </div>
      {props.question.value && (
        <span
          className={`flex justify-${
            count_position === CountPosition.LEFT ? "start" : "end"
          } ${count_position === CountPosition.HIDDEN ? "hidden" : ""}`}
        >
          Total Words:{" "}
          {questionValue
            ? questionValue.split(/\s+/).filter(Boolean).length
            : 0}
          {word_limit ? `/${word_limit}` : ""} (Characters:{" "}
          {questionValue ? questionValue.length : 0}
          {character_limit ? `/${character_limit}` : ""})
        </span>
      )}
    </div>
  );
};

export const RegisterReactTextboxComponent = () => {
  ElementFactory.Instance.registerElement(CUSTOM_TYPE_REACT_TEXTBOX, (name) => {
    return new ReactTextboxModel(name);
  });

  Serializer.addClass(
    CUSTOM_TYPE_REACT_TEXTBOX,
    [{ name: "height", default: "200px", category: "layout" }],
    function () {
      return new ReactTextboxModel("");
    },
    "question",
  );

  Serializer.addProperty(CUSTOM_TYPE_REACT_TEXTBOX, {
    name: "placeholder_text",
    displayName: "Placeholder text",
    type: "text",
    category: "general",
  });

  Serializer.addProperty(CUSTOM_TYPE_REACT_TEXTBOX, {
    name: "character_limit",
    displayName: "Character Limit (0 for no limit)",
    type: "number",
    category: "general",
  });

  Serializer.addProperty(CUSTOM_TYPE_REACT_TEXTBOX, {
    name: "word_limit",
    displayName: "Word Limit (0 for no limit)",
    type: "number",
    category: "general",
  });

  Serializer.addProperty(CUSTOM_TYPE_REACT_TEXTBOX, {
    name: "count_position",
    displayName: "Count Position",
    default: "left",
    category: "general",
    choices: [
      { value: CountPosition.LEFT, text: "Left" },
      { value: CountPosition.RIGHT, text: "Right" },
      { value: CountPosition.HIDDEN, text: "Hidden" },
    ],
  });

  Serializer.addProperty(CUSTOM_TYPE_REACT_TEXTBOX, {
    name: "textbox_rows",
    displayName: "Textbox Rows",
    type: "number",
    category: "general",
  });

  Serializer.addProperty(CUSTOM_TYPE_REACT_TEXTBOX, {
    name: "textbox_resize",
    displayName: "Textbox Resize",
    default: true,
    type: "boolean",
    category: "general",
  });

  ReactQuestionFactory.Instance.registerQuestion(
    CUSTOM_TYPE_REACT_TEXTBOX,
    (props: any) => {
      return React.createElement(ReactTextboxComponent, {
        ...props,
      });
    },
  );
};
