import { SessionView } from "shared/mappers/database/session/session";
import {
  ComponentCollection,
  ICustomQuestionTypeConfiguration,
  PanelModel,
  Question,
  QuestionCheckboxModel,
  QuestionRadiogroupModel,
  QuestionTagboxModel,
  Serializer,
} from "survey-core";
import { PillarChoiceDropdownModel } from "./PillarChoiceDropdownComponent";

export enum PillarChoiceQuestionType {
  Checkbox = "Checkbox",
  Tagbox = "Tagbox",
  Dropdown = "Dropdown",
  RadioGroup = "RadioGroup",
}
export const custom_question_pillar_choice = "pillar_choice";
export const pillar_choice = async (name: string, session: SessionView) => {
  const questionTitle = `Pillar Choice (${name})`;
  const registerProperties = () => {
    Serializer.addProperty(name, {
      name: "pillarChoiceCategory",
      displayName: "Pillar Choice Category",
      type: "dropdown",
      default: name,
      choices:
        session.society?.choiceCategory?.map(
          (category) => category.questionName ?? "",
        ) ?? [],
      category: "general",
    });
    Serializer.addProperty(name, {
      name: "showOtherItem",
      displayName: "Show Other Item",
      type: "boolean",
      default: false,
      category: "general",
    });
    Serializer.addProperty(name, {
      name: "questionType",
      displayName: "Question Type",
      choices: [
        PillarChoiceQuestionType.Checkbox,
        PillarChoiceQuestionType.Tagbox,
        PillarChoiceQuestionType.Dropdown,
        PillarChoiceQuestionType.RadioGroup,
      ],
      type: "radiogroup",
      default: PillarChoiceQuestionType.Checkbox,
      category: "general",
    });
    Serializer.addProperty(name, {
      name: "minSelectedChoices",
      displayName: "Miniumum Selected Choices",
      type: "number",
      category: "general",
    });
    Serializer.addProperty(name, {
      name: "maxSelectedChoices",
      displayName: "Maxiumum Selected Choices",
      type: "number",
      category: "general",
    });
    Serializer.addProperty(name, {
      name: "multi_select",
      displayName: "Multi select",
      type: "boolean",
      category: "general",
      visibleIf: function (obj: Question) {
        return obj.questionType === PillarChoiceQuestionType.Dropdown;
      },
    });
    Serializer.addProperty(name, {
      name: "placeholder_text",
      displayName: "Placeholder text",
      type: "text",
      category: "general",
      visibleIf: function (obj: Question) {
        return obj.questionType === PillarChoiceQuestionType.Dropdown;
      },
    });
    Serializer.addProperty(name, {
      name: "other_option_text",
      displayName: "Other option text",
      type: "text",
      category: "general",
      visibleIf: function (obj: Question) {
        return (
          obj.questionType === PillarChoiceQuestionType.Dropdown &&
          obj.showOtherItem
        );
      },
    });
  };
  registerProperties();

  const PillarChoiceDefinition: ICustomQuestionTypeConfiguration = {
    name: name,
    title: questionTitle,
    onInit: async () => {
      registerProperties();
    },
    onLoaded: (question: Question) => {
      question.name = name;
      buildPillarChoiceQuestionElements(question);
    },
    onAfterRender: (question: Question) => {
      buildPillarChoiceQuestionElements(question);
    },
    onPropertyChanged: (question: Question, propertyName: string) => {
      if (propertyName === "questionType") {
        buildPillarChoiceQuestionElements(question);
      }
    },
    createElements: () => {
      return [];
    },
  };

  const buildPillarChoiceQuestionElements = (question: Question) => {
    const panel = <PanelModel>question["contentPanel"];
    if (!panel) {
      return;
    }
    panel?.elements.forEach((element) => {
      panel.removeElement(element);
    });
    question.getPropertyByName("minSelectedChoices").visible = true;
    question.getPropertyByName("maxSelectedChoices").visible = true;
    buildPillarChoiceQuestionElementsMultipleChoice(question);
    buildPillarChoiceQuestionElementsTagbox(question);
    buildPillarChoiceQuestionElementsDropdown(question);
    buildPillarChoiceQuestionElementsRadioGroup(question);
  };
  const buildPillarChoiceQuestionElementsRadioGroup = (question: Question) => {
    const panel = <PanelModel>question["contentPanel"];
    const { questionType, pillarChoiceCategory, showOtherItem } = question;
    if (questionType === PillarChoiceQuestionType.RadioGroup) {
      const radioGroupPillarChoiceQuestion = new QuestionRadiogroupModel(name);
      radioGroupPillarChoiceQuestion.fromJSON({});
      //Add properties to the question
      Object.assign(radioGroupPillarChoiceQuestion, {
        title: "",
        isEditableTemplateElement: false,
        isInteractiveDesignElement: false,
        isContentElement: true,
        titleLocation: "hidden",
        choicesLazyLoadEnabled: true,
        choicesRestfull: true,
        showOtherItem,
        pillar_choices_question_name: pillarChoiceCategory,
      });
      //Add the question to the panel
      panel.addQuestion(radioGroupPillarChoiceQuestion);
    }
  };
  const buildPillarChoiceQuestionElementsDropdown = (question: Question) => {
    const panel = <PanelModel>question["contentPanel"];
    const {
      questionType,
      pillarChoiceCategory,
      showOtherItem,
      multi_select,
      minSelectedChoices,
      maxSelectedChoices,
      placeholder_text,
      other_option_text,
    } = question;
    if (questionType === PillarChoiceQuestionType.Dropdown) {
      const dropdownPillarChoiceQuestion = new PillarChoiceDropdownModel(name);

      Object.assign(dropdownPillarChoiceQuestion, {
        pillar_choices_question_name: pillarChoiceCategory,
        min_choices: minSelectedChoices,
        max_choices: maxSelectedChoices,
        multi_select,
        placeholder_text,
        title: "",
        other_option: showOtherItem,
        other_option_text,
        titleLocation: "hidden",
      });
      panel.addQuestion(dropdownPillarChoiceQuestion);
    }
  };
  const buildPillarChoiceQuestionElementsTagbox = (question: Question) => {
    const panel = <PanelModel>question["contentPanel"];
    const {
      questionType,
      minSelectedChoices,
      maxSelectedChoices,
      pillarChoiceCategory,
      showOtherItem,
    } = question;
    if (questionType === PillarChoiceQuestionType.Tagbox) {
      const tagboxPillarChoiceQuestion = new QuestionTagboxModel(name);
      tagboxPillarChoiceQuestion.fromJSON({
        maxSelectedChoices,
        minSelectedChoices,
      });
      //Add properties to the question
      Object.assign(tagboxPillarChoiceQuestion, {
        isEditableTemplateElement: false,
        isInteractiveDesignElement: false,
        isContentElement: true,
        choicesLazyLoadEnabled: true,
        pillar_choices_question_name: pillarChoiceCategory,
        title: "",
        showOtherItem,
        titleLocation: "hidden",
      });
      //Add the question to the panel
      panel.addQuestion(tagboxPillarChoiceQuestion);
    }
  };
  const buildPillarChoiceQuestionElementsMultipleChoice = (
    question: Question,
  ) => {
    const panel = <PanelModel>question["contentPanel"];
    const {
      questionType,
      minSelectedChoices,
      maxSelectedChoices,
      pillarChoiceCategory,
      showOtherItem,
    } = question;

    if (questionType === PillarChoiceQuestionType.Checkbox) {
      const multipleChoicePillarChoiceQuestion = new QuestionCheckboxModel(
        name,
      );
      multipleChoicePillarChoiceQuestion.fromJSON({
        maxSelectedChoices,
        minSelectedChoices,
      });
      //Add properties to the question
      Object.assign(multipleChoicePillarChoiceQuestion, {
        title: "",
        isEditableTemplateElement: false,
        isInteractiveDesignElement: false,
        isContentElement: true,
        titleLocation: "hidden",
        choicesLazyLoadEnabled: true,
        showOtherItem,
        pillar_choices_question_name: pillarChoiceCategory,
      });
      //Add the question to the panel
      panel.addQuestion(multipleChoicePillarChoiceQuestion);
    }
  };

  if (!ComponentCollection.Instance.getCustomQuestionByName(name)) {
    ComponentCollection.Instance.add(PillarChoiceDefinition);
  }
};
