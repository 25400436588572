import { useIonRouter } from "@ionic/react";
import { useEffect, useState } from "react";

const useLastPathName = () => {
  const [path, setPath] = useState<string | undefined>(undefined);
  const routes = useIonRouter();

  useEffect(() => {
    const lastPath = routes?.routeInfo?.lastPathname?.replace("/", "");

    if (lastPath?.length) {
      setPath(lastPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { path };
};

export default useLastPathName;
