import { ApiResponse } from "admin/src/ui/types/common/api-response";
import { ApiMutationConfig } from "hub/src/api/types";

const SaveFCMToken: ApiMutationConfig<
  ApiResponse<any>, // Update this after new Type version
  {
    societyId: string;
  },
  {
    fcmToken: string;
    platform: string;
  }
> = {
  apiPath: "api/v1/society/{societyId}/notification/mobile-app-token",
  method: "POST",
  invalidates: [],
};

export { SaveFCMToken };
