import { useEffect, useRef } from "react";

type Callback = () => void;

export const useDebounce = <T extends unknown[]>(
  callback: Callback,
  deps: T,
  delay: number,
) => {
  const callbackRef = useRef<Callback>(callback);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    callbackRef.current = callback;
  }, deps);

  useEffect(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(callbackRef.current, delay);
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, deps.concat(delay));
};
