import {
  SocietyWorkflowParamsInput,
  SocietyWorkflowBodyInput,
} from "shared/api/types/society/[societyId]/workflow/[workflowId]/instance/index";
import { WorkflowInstanceStopped } from "admin/src/server/bll/workflow/instance/WorkflowInstanceRunner";
import { BaseApiContract } from "admin/src/ui/api-callouts/utils";
import { HttpMethod } from "admin/src/ui/types/common/http";
export const postSocietyWorkflowInstanceContract: BaseApiContract<
  SocietyWorkflowParamsInput,
  SocietyWorkflowBodyInput,
  WorkflowInstanceStopped
> = {
  method: HttpMethod.POST,
  apiRoute: "/api/v1/society/[societyId]/workflow/[workflowId]/instance",
  paramsType: {} as SocietyWorkflowParamsInput,
  bodyType: {} as SocietyWorkflowBodyInput,
  responseType: {} as WorkflowInstanceStopped,
};
