import { Question, Serializer } from "survey-core";

export const globalValidationProperties = () => {
  Serializer.addProperty("question", {
    name: "wordLimit",
    displayName: "Maximum word limit",
    type: "number",
    category: "validation",
    visibleIf: function (obj: Question) {
      return obj.getType() == "comment" || obj.getType() == "text";
    },
  });

  Serializer.addProperty("question", {
    name: "withSpaces",
    displayName: "Include spaces as words?",
    type: "boolean",
    category: "validation",
    visibleIf: function (obj: Question) {
      return obj.wordLimit > 0;
    },
  });
};
