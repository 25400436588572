import { FileReference } from "admin/src/constants/enums/file-reference";
import { Serializer } from "survey-core";

export const fileQuestionProperties = () => {
  Serializer.addProperty("file", {
    name: "isPublic",
    type: "boolean",
    category: "general",
    default: true,
    visibleIndex: 1,
  });
  Serializer.addProperty("file", {
    name: "FileReference",
    type: "dropdown",
    choices: Object.values(FileReference),
    category: "general",
    default: "profile",
    visibleIndex: 1,
  });
};
