import { ComponentPropsWithoutRef } from "react";

type ChevronDownIconProps = ComponentPropsWithoutRef<"svg">;

const ChevronDownIcon = (props: ChevronDownIconProps) => {
  return (
    <span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-chevron-down"
        width={32}
        height={32}
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <polyline points="6 9 12 15 18 9" />
      </svg>
    </span>
  );
};

export default ChevronDownIcon;
