import PageComponent from "hub/src/components/common/PageComponent";
import useHubSessionContext from "hub/src/hooks/useHubSessionContext";

const AccessDeniedPage = () => {
  const session = useHubSessionContext();

  return (
    <PageComponent>
      <PageComponent.Header></PageComponent.Header>
      <PageComponent.Content>
        <div className="text-center py-24 px-8 h-full">
          <p className="text-base font-semibold text-primary">Access Denied</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-dark sm:text-5xl">
            Oops!
          </h1>
          <p className="mt-6 text-base leading-7 text-medium">
            {/* TODO: We need to either remove this eslint rule or fix it. I recommend we just remove the rule */}
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            You don't have permission to access this page.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href={session.societyUserId ? "/overview" : "/login"}
              className="text-sm font-semibold text-primary hover:text-primary-tint"
            >
              Go to {session.societyUserId ? "Home" : "login"} page{" "}
              <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div>
      </PageComponent.Content>
    </PageComponent>
  );
};

export default AccessDeniedPage;
