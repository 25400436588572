import { FormikValues, useFormikContext } from "formik";

import React, { ComponentPropsWithoutRef } from "react";

import NewAppInput, { NewAppInputProps } from "./NewAppInput";
import { get } from "lodash";

export type NewAppNumberInputProps = NewAppInputProps &
  Omit<ComponentPropsWithoutRef<"input">, "id" | "name" | "value" | "type">;

const NewAppNumberInput = ({
  name,
  label,
  labelClassName,
  additionalClasses,
  defaultValue,
  onChange,
  ...props
}: NewAppNumberInputProps) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const value = get(values, name);
  return (
    <NewAppInput
      name={name}
      labelClassName={labelClassName}
      label={label}
      additionalClasses={additionalClasses}
    >
      <input
        type="number"
        value={value}
        defaultValue={defaultValue}
        placeholder={props.placeholder?.toString()}
        name={name}
        id={name}
        data-testid={props.testid}
        onChange={(e) => {
          setFieldValue(name, e.target.value);
          onChange?.(e);
        }}
        {...props}
      />
    </NewAppInput>
  );
};

export default NewAppNumberInput;
