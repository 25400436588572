import useHubSessionContext from "hub/src/hooks/useHubSessionContext";
import { ComponentPropsWithoutRef, useState } from "react";

type SocietyLogoProps = {
  type?: "login" | "banner";
} & ComponentPropsWithoutRef<"div">;

const SocietyLogo = ({ className, type, ...props }: SocietyLogoProps) => {
  const session = useHubSessionContext();

  const [isLoading, setIsLoading] = useState(true);

  return (
    <img
      src={
        type === "banner"
          ? session.society?.societySettingsPublic?.societyLogoBannerUrl
          : session.society?.societySettingsPublic?.societyLogoLoginUrl
      }
      alt="Society Logo"
      className={`society-image object-cover ${
        className ? className : "w-4.5 h-4.5"
      } ${isLoading ? "loading" : ""}`}
      onLoad={() => setIsLoading(false)}
      {...props}
    />
  );
};

export { SocietyLogo };
