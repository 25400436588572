import React, { ComponentPropsWithoutRef } from "react";

export type TabProps = ComponentPropsWithoutRef<"div"> & {
  name: string;
  titleText?: React.ReactNode;
  children?: React.ReactNode;
};

// This var is used in Tabs.tsx to dynamically name the top tabs.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Tab = ({ children, className, titleText, ...props }: TabProps) => {
  return (
    <div
      className={`tabs-content-container-outer ${className ?? ""}`}
      {...props}
    >
      <div className="tabs-content-container-inner">{children}</div>
    </div>
  );
};
