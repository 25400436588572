import { SubmissionInstanceView } from "shared/mappers/database/submission/instance/submission-instance";
import { SubmissionDefinitionView } from "shared/mappers/database/submission/submission-definition/society-submission-definition";
import { renderTemplateUtil } from "admin/src/utils/template/renderTemplateUtil";

export type submissionTemplateHelperProps = {
  submissionParticipant?: SubmissionInstanceView;
  submissionInstance: SubmissionInstanceView;
  submissionDefinition?: SubmissionDefinitionView;
  type:
    | "titleTemplate"
    | "additionalInfoTemplateDetailed"
    | "additionalInfoParticipantTemplate"
    | "additionalInfoTemplateShort";
};
export const submissionTemplateHelper = ({
  submissionInstance,
  submissionDefinition,
  type,
}: submissionTemplateHelperProps): string | undefined => {
  //submissionInstance may contain a submissionDefinition if expect it to be passed in separately

  let template =
    submissionInstance?.[type] ??
    submissionInstance?.submissionDefinition?.[type] ??
    submissionDefinition?.[type];
  if (template === undefined && type === "titleTemplate") {
    template = "{{workflowInstance.payload.submission_title}}";
  }
  return template
    ? renderTemplateUtil({
        template,
        templateData: submissionInstance,
      })
    : undefined;
};
