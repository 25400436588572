import { SocietyProfileInvoiceInvoiceItemDiscountCodeParamsInput } from "shared/api/types/society/[societyId]/profiles/[profileId]/accounting/invoice/[invoiceId]/item/[invoiceItemId]/discountCode/[discountCodeString]";
import { InvoiceView } from "shared/mappers/database/accounting/invoice/invoice";
import { BaseApiContract } from "admin/src/ui/api-callouts/utils";
import { HttpMethod } from "admin/src/ui/types/common/http";
export const postSocietyProfileAccountingInvoiceItemDiscountCodeStringContract: BaseApiContract<
  SocietyProfileInvoiceInvoiceItemDiscountCodeParamsInput,
  undefined,
  InvoiceView
> = {
  method: HttpMethod.POST,
  apiRoute:
    "/api/v1/society/[societyId]/profiles/[profileId]/accounting/invoice/[invoiceId]/item/[invoiceItemId]/discountCode/[discountCodeString]",
  paramsType: {} as SocietyProfileInvoiceInvoiceItemDiscountCodeParamsInput,
  responseType: {} as InvoiceView,
};
