import { form_category } from "@prisma/client";
import { Serializer } from "survey-core";

export const formProperties = () => {
  Serializer.addProperty("survey", {
    name: "society_id",
    type: "number",
    displayName: "Society ID",
    readOnly: true,
    category: "general",
  });
  Serializer.addProperty("survey", {
    name: "form_id",
    type: "number",
    displayName: "Form ID",
    readOnly: true,
  });
  Serializer.addProperty("survey", {
    name: "created_at",
    type: "date",
    displayName: "Created At",
    readOnly: true,
  });
  Serializer.addProperty("survey", {
    name: "created_by",
    type: "string",
    displayName: "Created By",
    readOnly: true,
  });
  Serializer.addProperty("survey", {
    name: "modified_by",
    type: "string",
    displayName: "Modified By",
    readOnly: true,
  });
  Serializer.addProperty("survey", {
    name: "archived",
    type: "boolean",
    category: "general",
  });
  Serializer.addProperty("survey", {
    name: "locked",
    type: "boolean",
    category: "general",
  });
  Serializer.addProperty("survey", {
    name: "category",
    type: "dropdown",
    category: "general",
    choices: Object.values(form_category),
  });
};
