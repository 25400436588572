import { Serializer } from "survey-core";

export enum WorkflowPropertiesSurveyJS {
  workflowId = "workflowId",
  workflowInstanceId = "workflowInstanceId",
  submissionDefinitionId = "submissionDefinitionId",
  submissionInstanceId = "submissionInstanceId",
  conferenceRegistrationId = "conferenceRegistrationId",
  conferenceId = "conferenceId",
}
export const registerWorkflowPropertiesSurveyJS = () => {
  Serializer.addProperty("survey", {
    name: WorkflowPropertiesSurveyJS.workflowId,
    type: "number",
    isRequired: false,
    category: "general",
  });
  Serializer.addProperty("survey", {
    name: WorkflowPropertiesSurveyJS.workflowInstanceId,
    type: "number",
    category: "general",
    visible: false,
  });
  Serializer.addProperty("survey", {
    name: WorkflowPropertiesSurveyJS.submissionDefinitionId,
    type: "number",
    category: "general",
    visible: false,
  });
  Serializer.addProperty("survey", {
    name: WorkflowPropertiesSurveyJS.submissionInstanceId,
    type: "number",
    category: "general",
    visible: false,
  });
  Serializer.addProperty("survey", {
    name: WorkflowPropertiesSurveyJS.conferenceRegistrationId,
    type: "number",
    category: "general",
    visible: false,
  });
  Serializer.addProperty("survey", {
    name: WorkflowPropertiesSurveyJS.conferenceId,
    type: "number",
    category: "general",
    visible: false,
  });
};
