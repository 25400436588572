import { Question } from "survey-core";
import { SurveyCreatorModel } from "survey-creator-core";
export const generateIteratedQuestionName = (
  questionName: string,
  creatorModel: SurveyCreatorModel,
) => {
  const questionArray = creatorModel.survey.getAllQuestions();
  const allQuestions =
    questionArray &&
    questionArray.map((question: Question) => question.getType());
  const questionInstanceCount = allQuestions?.filter(
    (name: string) => name === questionName,
  ).length;

  return `${questionName.toString()}${
    questionInstanceCount === 1 ? "" : "_" + questionInstanceCount
  }`;
};
