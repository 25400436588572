import { Spinner } from "hub/src/components/common/Spinner";
import NewAppForm from "admin/src/ui/components/common/newform/NewAppForm";
import NewAppTextInput from "admin/src/ui/components/common/newform/NewAppTextInput";
import { AddressBodyInputFullAddress } from "admin/src/ui/types/address";

type Props = {
  addressType: string;
  sameAsBilling?: boolean;
  handleSubmit?: () => void;
  setAddressState: (details: AddressBodyInputFullAddress) => void;
  addressState: AddressBodyInputFullAddress;
};

export const AddressForm = (props: Props) => {
  return (
    <NewAppForm
      handleSubmit={() => {
        //void
      }}
      handleChanged={props.setAddressState}
      overrideInitialValues={props.addressState}
    >
      <h4 className="text-subheader">{props.addressType} Address</h4>
      {!props.sameAsBilling && (
        <div className="pt-1">
          {!props.addressState ? (
            <Spinner />
          ) : (
            <>
              <div>
                <NewAppTextInput
                  additionalClasses="w-full rounded-md "
                  name="line1"
                  label="Address"
                  placeholder="Enter Address"
                />
              </div>
              <div className="grid grid-cols-2 gap-2 pt-2">
                <div className="pt-1">
                  <NewAppTextInput
                    additionalClasses="rounded-md"
                    name="city"
                    label="City"
                    placeholder="Enter City"
                  />
                </div>
                <div className="pt-1 ">
                  <NewAppTextInput
                    additionalClasses="rounded-md "
                    name="state"
                    label="State"
                    placeholder="Enter State"
                  />
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </NewAppForm>
  );
};
