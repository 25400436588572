export const NON_MEMMBER = "Non Member" as const;

export const INVOICE_STATUS = {
  OPEN: "OPEN",
  PAID: "PAID",
} as const;

export const SurveyJSViewMode = {
  Edit: "edit",
  Read_Only: "display",
} as const;
