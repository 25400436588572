import { useHistory } from "react-router";
import { useState, useCallback } from "react";
import PageComponent from "hub/src/components/common/PageComponent";
import LoadingPage from "hub/src/components/common/LoadingPage";
import { getSocietyId } from "hub/src/utils/getSocietyId";
import { getDownloadFileUrlCallout } from "admin/src/ui/api-callouts/file/get";
import { useDownloadFileByLink } from "admin/src/ui/hooks/useDownloadFileByLink";
import { useSecondsTimerWithCallback } from "admin/src/ui/hooks/useSecondsTimerWithCallback";
import { ENV } from "../appConstants/env";
import { HubRouteConfig } from "hub/src/routes/RouteConfigWrapper";

const SECONDS_TO_AUTO_DOWNLOAD = 5;

export type HubSharedUrlsPageRouteParams = {
  fileId: string;
};
const SharedUrlsPage = (
  routeConfig: HubRouteConfig<HubSharedUrlsPageRouteParams>,
) => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const { downloadFileFunction } = useDownloadFileByLink();

  const { fileId } = routeConfig.routeParams!;

  const autoDownload = useCallback(async () => {
    if (!fileId) return;

    try {
      setIsLoading(true);
      const url = await getDownloadFileUrlCallout({
        fileIdOrSlug: fileId,
        societyId: getSocietyId(),
        baseUrl: ENV.REACT_APP_PILLAR_API_URL,
      });

      await downloadFileFunction({ url });
      history.replace("/overview");
    } catch (error) {
      history.replace("/access-denied");
    } finally {
      setIsLoading(false);
    }
  }, [fileId]);

  const onTimerEnd = useCallback(() => {
    autoDownload();
  }, [autoDownload]);

  const { time } = useSecondsTimerWithCallback({
    initialSeconds: SECONDS_TO_AUTO_DOWNLOAD,
    callback: onTimerEnd,
  });

  if (isLoading) return <LoadingPage />;

  return (
    <PageComponent>
      <PageComponent.Content>
        <div className="container-page-padding">
          <div>
            Please wait while your download initiates. It will commence in{" "}
            <b>{time}s</b>.
          </div>
          <div>
            If the download does not begin automatically, you can manually start
            it by clicking <a onClick={autoDownload}>here</a>
          </div>
        </div>
      </PageComponent.Content>
    </PageComponent>
  );
};

export default SharedUrlsPage;
