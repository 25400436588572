import { toastController } from "@ionic/core";
import { ToastOptions } from "@ionic/react";

enum ToastStyle {
  Error = "toast-error",
  Info = "toast-info",
  Warning = "toast-warning",
  Success = "toast-success",
}

const showToast = async (
  message: string,
  style: ToastStyle,
  options?: ToastOptions,
) => {
  let duration = 5000;
  if (style === ToastStyle.Error) {
    duration = 10000;
  }
  const toast = await toastController.create({
    duration: duration,
    position: "top",
    cssClass: style.toString(),
    ...(options ?? {}),
    message: message,
  });

  return toast.present();
};

export { showToast, ToastStyle };
