import {
  TokenQuery,
  SpecialResponse,
} from "shared/api/types/society/[societyId]/token/[...token_value]";
import { BaseApiContract } from "admin/src/ui/api-callouts/utils";
import { HttpMethod } from "admin/src/ui/types/common/http";
export const getSocietyTokenTokenValueContract: BaseApiContract<
  TokenQuery,
  undefined,
  SpecialResponse
> = {
  method: HttpMethod.GET,
  apiRoute: "/api/v1/society/[societyId]/token/[...token_value]",
  paramsType: {} as TokenQuery,
  responseType: {} as SpecialResponse,
};
