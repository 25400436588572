/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
  useMutation,
} from "@tanstack/react-query";
import {
  ApiMutationConfig,
  MutationBodyParamsType,
  MutationPathParamsType,
  MutationReturnType,
  apiClient,
  buildApiClient,
} from "hub/src/api";
import { DynamicQueryKey } from "hub/src/api/types/dynamic-query-key";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { queryClient } from "./query-client";
import { generateQueryKey, interpolateApiPath } from "./utils";

const axiosConfigNonOverridable = (
  method: "POST" | "PUT" | "PATCH" | "DELETE",
  path: string,
) => {
  return {
    method: method,
    url: path,
  };
};
const axiosConfigOverridable: AxiosRequestConfig = {
  timeout: 30 * 1000,
};

const invalidateQueries = (
  invalidates: (DynamicQueryKey & { exact?: boolean })[],
  data: Record<string, any> | Record<string, any>[],
  pathParams?: Record<string, any>,
  bodyParams?: Record<string, any>,
) => {
  invalidates?.forEach((invalidationKey) => {
    // TODO: This only currently handles data that starts at root. For most use cases this is fine, but for some deeply nested keys, this may not work.
    // We do account for a "body" and a "results" to handle our two common cases of ApiResponse and Pagination
    // Ex:
    // {
    //   someMeta: "stuff",
    //  paginatedResults:  [
    //     {
    //       moreMeta: "things",
    //       items: [
    //         {
    //           theIdThingWeNeed: 1
    //         }
    //       ]
    //     }
    //   ]

    // }
    const apiResponseData =
      typeof data === "object" && "body" in data ? data.body : data;
    const paginationData =
      "results" in apiResponseData ? apiResponseData.results : apiResponseData;
    if (invalidationKey.dynamicQueryKey && Array.isArray(paginationData)) {
      data.forEach((element: Record<string, any>) => {
        const key = generateQueryKey(
          invalidationKey.baseQueryKey,
          pathParams,
          bodyParams,
          element,
          invalidationKey.dynamicQueryKey,
          false,
        );
        queryClient.invalidateQueries({
          queryKey: key,
          exact:
            invalidationKey.exact === undefined ? false : invalidationKey.exact,
        });
      });
    } else {
      const key = generateQueryKey(
        invalidationKey.baseQueryKey,
        pathParams,
        bodyParams,
        paginationData,
        invalidationKey.dynamicQueryKey,
        false,
      );
      queryClient.invalidateQueries({
        queryKey: key,
        exact:
          invalidationKey.exact === undefined ? false : invalidationKey.exact,
      });
    }
  });
};

const tanstackConfigOverridable = <ReturnType, BodyParamsType>(
  mutationFn: MutationFunction<AxiosResponse<ReturnType, any>, BodyParamsType>,
): UseMutationOptions<
  AxiosResponse<ReturnType>,
  Error,
  BodyParamsType,
  unknown
> => {
  return {
    mutationFn: mutationFn,
    networkMode: "online",
    cacheTime: 10 * 60 * 1000,
  };
};

const useApiMutation = <
  T extends ApiMutationConfig<any, Record<string, any>, Record<string, any>>,
  ReturnType extends MutationReturnType<T>,
  PathParamsType extends MutationPathParamsType<T>,
  BodyParamsType extends MutationBodyParamsType<T>,
>(
  apiMutationConfig: T,
  pathParams?: PathParamsType,
  axiosRequestConfig?: AxiosRequestConfig,
  tanstackConfig?: UseMutationOptions<
    AxiosResponse<ReturnType>,
    Error,
    BodyParamsType,
    unknown
  >,
): UseMutationResult<
  AxiosResponse<ReturnType>,
  Error,
  BodyParamsType,
  unknown
> => {
  buildApiClient();

  const path = interpolateApiPath(apiMutationConfig.apiPath, pathParams);

  const mergedAxiosRequestConfig: AxiosRequestConfig = {
    ...axiosConfigOverridable,
    ...(axiosRequestConfig || {}),
    ...axiosConfigNonOverridable(apiMutationConfig.method, path),
  };

  const mutationFn = async (bodyParams: Record<string, any>) => {
    // TODO: Weird auth url params expectation
    // This is a very specific strange case where our API expects url search params as the body for this route.
    // We should fix our API to not do this and just take a JSON body.
    let params = bodyParams;
    if (
      Object.keys(bodyParams).length === 1 &&
      "urlParamaterizedAuth" in bodyParams
    ) {
      params = new URLSearchParams(bodyParams["urlParamaterizedAuth"]);
    }
    const axiosResponse = await apiClient!({
      ...mergedAxiosRequestConfig,
      url: path,
      data: params,
    });

    if (axiosResponse.status >= 200 && axiosResponse.status < 300) {
      invalidateQueries(
        apiMutationConfig.invalidates || [],
        axiosResponse.data,
        pathParams,
        bodyParams,
      );
    }

    return axiosResponse;
  };

  const mergedTanstackConfig: typeof tanstackConfig = {
    ...tanstackConfigOverridable<ReturnType, BodyParamsType>(mutationFn),
    ...(tanstackConfig || {}),
  };

  const mutation = useMutation<
    AxiosResponse<ReturnType>,
    Error,
    BodyParamsType,
    unknown
  >(mergedTanstackConfig);

  return mutation;
};

const mutateApiQuery = async <
  T extends ApiMutationConfig<any, Record<string, any>, Record<string, any>>,
  ReturnType extends MutationReturnType<T>,
  PathParamsType extends MutationPathParamsType<T>,
  BodyParamsType extends MutationBodyParamsType<T>,
>(
  apiMutationConfig: T,
  pathParams?: PathParamsType,
  bodyParams?: BodyParamsType,
  axiosRequestConfig?: AxiosRequestConfig,
): Promise<AxiosResponse<ReturnType, any>> => {
  buildApiClient();

  const path = interpolateApiPath(apiMutationConfig.apiPath, pathParams);

  const mergedAxiosRequestConfig: AxiosRequestConfig = {
    ...axiosConfigOverridable,
    ...(axiosRequestConfig || {}),
    ...axiosConfigNonOverridable(apiMutationConfig.method, path),
  };

  //TODO: This functionally doesn't cause any issues afaik, but need to confirm for sure or change.
  //eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiClient!({
        ...mergedAxiosRequestConfig,
        data: bodyParams,
        url: path,
      });

      invalidateQueries(apiMutationConfig.invalidates || [], response.data);

      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export { mutateApiQuery, useApiMutation };
