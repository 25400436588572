export namespace AuthorizeAcceptSuite {
  export class PaymentProcessor {
    secureData: SecureData;

    constructor(secureData: SecureData) {
      this.secureData = secureData;
    }

    async getResponse(): Promise<DispatchDataResponse> {
      const securedDataResp = await new Promise<DispatchDataResponse>(
        (resolve) => {
          // Simulate calling a method from an external library (like window.Accept.dispatchData)
          window.Accept.dispatchData(
            this.secureData,
            (response: DispatchDataResponse) => {
              resolve(response);
            }
          );
        }
      );

      return securedDataResp;
    }
  }

  export class CardData {
    cardNumber: string;
    month: string;
    year: string;
    cardCode?: string;
    zip?: string;
    fullName?: string;

    constructor(
      cardNumber: string,
      month: string,
      year: string,
      cardCode?: string,
      zip?: string,
      fullName?: string
    ) {
      this.cardNumber = cardNumber;
      this.month = month;
      this.year = year;
      this.cardCode = cardCode;
      this.zip = zip;
      this.fullName = fullName;
    }
  }

  export class AuthData {
    clientKey: string;
    apiLoginID: string;

    constructor(clientKey: string, apiLoginID: string) {
      this.clientKey = clientKey;
      this.apiLoginID = apiLoginID;
    }
  }

  export class SecureData {
    authData: AuthData;
    cardData: CardData;

    constructor(authData: AuthData, cardData: CardData) {
      this.authData = authData;
      this.cardData = cardData;
    }
  }

  export interface AcceptMessage {
    code: string;
    description: string;
  }

  export type AcceptSuiteErrorMessage = { code: string; text: string };

  export type DispatchDataResponse = {
    opaqueData: {
      dataDescriptor: string;
      dataValue: string;
    };
    messages: {
      resultCode: "Ok" | "Error";
      message: AcceptSuiteErrorMessage[];
    };
  };

  export type DispatchDataFn = (
    secureData: SecureData,
    responseHandler: DispatchDataResponse
  ) => void;
}
