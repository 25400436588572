export enum HttpMethod {
  GET = "GET",
  POST = "POST",
  PATCH = "PATCH",
  DELETE = "DELETE",
  PUT = "PUT",
  OPTIONS = "OPTIONS",
}

export enum HttpResponseStatusCodes {
  Info_Min = 100,
  Info_Max = 199,
  Successful_Min = 200,
  Successful_Max = 299,
  Redirection_Min = 300,
  Redirection_Max = 399,
  Client_Error_Mix = 400,
  Client_Error_Max = 499,
  Server_Error_Min = 500,
  Server_Error_Max = 599,
}
