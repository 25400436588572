import { removeHTMLTags } from "admin/src/utils/helpers/removeHTMLTags";
import { useEffect, useRef, useState } from "react";
import { SessionView } from "shared/mappers/database/session/session";
import { StylesManager, SurveyModel } from "survey-core";
import "survey-core/defaultV2.min.css";
import { Survey } from "survey-react-ui";
import {
  initializeSurveyModel,
  InitializeSurveyModelProps,
} from "./initializeSurveyModel";

StylesManager.applyTheme("defaultV2");

export interface SurveyPreviewProps
  extends React.ComponentProps<typeof Survey>,
    InitializeSurveyModelProps {
  session: SessionView;
  spinner: any;
  overrideTitle?: string;
  overrideDescription?: string;
}

export const SurveyPreview = (props: SurveyPreviewProps) => {
  const initializedRef = useRef(false);
  const modelRef = useRef<SurveyModel | null>(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const initializeModel = async () => {
      const newModel = await initializeSurveyModel({ ...props });

      if (props.overrideTitle) {
        newModel.title = props.overrideTitle;
      }
      if (props.overrideDescription) {
        newModel.description = props.overrideDescription;
      }
      newModel.setVariable("profile", props.session.profile);
      newModel.getAllQuestions().forEach((question) => {
        if (question.title.includes("<p>")) {
          question.title = removeHTMLTags(question.title);
        }
      });
      modelRef.current = newModel;
      initializedRef.current = true;
      setIsReady(true);
    };

    initializeModel();

    return () => {
      if (modelRef.current) {
        console.log("current and disposting");
        modelRef.current.dispose();
        modelRef.current = null;
      }
      initializedRef.current = false;
      console.log("setting ready to false");
      setIsReady(false);
    };
  }, []);

  if (!modelRef.current && !initializedRef.current) {
    return props.spinner;
  }

  if (isReady) {
    return <Survey className="surveyjs" model={modelRef.current} {...props} />;
  }
};
