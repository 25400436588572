type Props = {
  url?: string;
};

const NativeAppPDFViewer = ({ url }: Props) => {
  return (
    <>
      {/* This component is intended exclusively for use in the Native App, as
        pdf-reactjs is not compatible with native apps. If you need to display a
        PDF on the web, please use PDFViewerWrapper. */}
      <iframe title={"PDF Viewer"} src={url ?? ""} className="w-full h-full" />
    </>
  );
};

export default NativeAppPDFViewer;
