import { getDisplayNameHelper } from "admin/src/utils/helpers/profile/getDisplayNameHelper";
import useHubSessionContext from "hub/src/hooks/useHubSessionContext";
import { DateTime } from "luxon";

const AdminImpersonateInfo: React.FC = () => {
  const session = useHubSessionContext();
  if (session.societyAdminId || session.environment !== "prod") {
    return (
      <div className="justify-normal">
        {session.environment !== "prod" && (
          <>
            <div>
              {session.environment} - {session.iat}
            </div>
            <div>{DateTime.now().toISO()}</div>
          </>
        )}
        {session.societyAdminId || session.environment !== "prod" ? (
          <>
            <div data-testid="impersonate-admin-user">
              Admin : {session.societyAdmin?.name} ({session.societyAdminId})
            </div>
            <div data-testid="impersonate-admin-mode">
              Admin Mode: {session.adminMode ? "ON" : "OFF"}
            </div>
            <div data-testid="impersonate-society-user">
              User: {session.societyUser?.firstName}{" "}
              {session.societyUser?.lastName} ({session.societyUser?.profileId})
            </div>
            <div data-testid="impersonate-profile">
              Profile: {getDisplayNameHelper(session.profile)} (
              {session.profileId})
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    );
  }
  return <></>;
};

export default AdminImpersonateInfo;
