import { Children, ReactElement, ReactNode, isValidElement } from "react";

export const flattenChildComponents = (children: ReactNode): ReactElement[] => {
  return Children.toArray(children).reduce<ReactElement[]>((acc, child) => {
    if (isValidElement(child)) {
      return acc.concat(child, flattenChildComponents(child.props.children));
    }
    return acc;
  }, []);
};
