const PROFILE_ID_KEY = "profileId";

export const getProfileId = () => {
  const profileId = sessionStorage.getItem(PROFILE_ID_KEY);

  return Number(profileId);
};

export const setProfileId = (newProfileId: number) => {
  sessionStorage.setItem(PROFILE_ID_KEY, String(newProfileId));
};

export const clearProfileId = () => {
  sessionStorage.removeItem(PROFILE_ID_KEY);
};
