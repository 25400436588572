/* eslint-disable @typescript-eslint/no-explicit-any */
import { QueryKey } from "@tanstack/react-query";

const combineQueryKey = (
  baseKey: QueryKey,
  params: Record<string, any>,
  dynamicKey?: string[],
) => {
  const theKey =
    dynamicKey && dynamicKey?.length > 0
      ? dynamicKey.reduce((accQueryKey, key) => {
          const keyArr = (key as string).split(".");
          const keySuffix =
            keyArr.length > 0 && Object.keys(params).includes(keyArr[0])
              ? keyArr.reduce((obj, key) => obj?.[key], params)
              : undefined;
          return keySuffix
            ? accQueryKey.concat([stringifyKey(keySuffix)])
            : accQueryKey;
        }, baseKey)
      : baseKey;
  return theKey;
};

const generateQueryKeyState = (
  pathParams?: Record<string, any>,
  bodyParams?: Record<string, any>,
) => {
  return {
    pathParams: pathParams,
    bodyParams: bodyParams,
  };
};

const generateQueryKey = (
  baseKey: QueryKey,
  pathParams?: Record<string, any>,
  bodyParams?: Record<string, any>,
  entityBody?: Record<string, any>,
  dynamicQueryKey?: string[],
  addKeyState = true,
) => {
  const combinedKeyBase = combineQueryKey(
    baseKey,
    { ...(pathParams ?? {}), ...(bodyParams ?? {}), ...(entityBody ?? {}) },
    dynamicQueryKey,
  );
  let key = combinedKeyBase;
  if (addKeyState) {
    const keyState = generateQueryKeyState(pathParams, bodyParams);
    key = [...key, keyState];
  }
  return key;
};

const interpolateApiPath = (
  apiPath: string,
  pathParams?: Record<string, any>,
) => {
  return pathParams
    ? Object.entries(pathParams).reduce((accPath, [key, value]) => {
        const derivedValue = typeof value === "function" ? value() : value;
        return accPath.replace(`{${key}}`, derivedValue);
      }, apiPath)
    : apiPath;
};

const stringifyKey = (key: any) => {
  return key instanceof Object ? JSON.stringify(key) : String(key);
};

export { generateQueryKey, interpolateApiPath, combineQueryKey };
