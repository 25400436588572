import { ComponentPropsWithoutRef, ReactNode } from "react";

type MainContentWrapperProps = ComponentPropsWithoutRef<"div"> & {
  children: ReactNode;
  computedMatch?: object;
};

const MainContentWrapper = ({
  children,
  ...props
}: MainContentWrapperProps) => {
  // We need to omit computedMatch prop because ReactRouter always passes it to the child component
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { computedMatch, ...restProps } = props;

  return <div {...restProps}>{children}</div>;
};

export default MainContentWrapper;
