export const removeFilePrefix = (str?: string): string => {
  if (!str) return "";

  const parts = str.split("/");

  if (!parts) return str;

  const filename = parts[parts.length - 1];

  const underscoreIndex = filename.indexOf("_");
  if (underscoreIndex !== -1) return filename.substring(underscoreIndex + 1);

  return filename;
};
