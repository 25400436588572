export const formatPhoneOrFaxNumberString = (
  inputStr: string,
  singleCountryCode?: boolean,
) => {
  let digits = inputStr.replace(/\D/g, "");
  if (!singleCountryCode) {
    if (digits.length > 13) {
      digits = digits.substring(0, 13);
    }
    return digits;
  }

  const countryCode = digits.substring(0, 1);
  const areaCode = digits.substring(1, 4);
  const prefix = digits.substring(4, 7);
  const lineNumber = digits.substring(7, 11);

  let formattedNumber = "";
  if (digits.length > 7) {
    formattedNumber = `${countryCode} (${areaCode}) ${prefix}-${lineNumber}`;
  } else if (digits.length > 4) {
    formattedNumber = `${countryCode} (${areaCode}) ${prefix}`;
  } else if (digits.length > 1) {
    formattedNumber = `${countryCode} (${areaCode}`;
  } else {
    formattedNumber = countryCode;
  }
  return formattedNumber;
};
