import { SocietyProfileReviewAssignmentParamsInput } from "shared/api/types/society/[societyId]/profiles/[profileId]/review/assignments/[reviewAssignmentId]";
import { RecuseAssignmentBodyInput } from "shared/api/types/society/[societyId]/profiles/[profileId]/review/assignments/[reviewAssignmentId]/recuse";
import { BaseApiContract } from "admin/src/ui/api-callouts/utils";
import { HttpMethod } from "admin/src/ui/types/common/http";
export const patchSocietyProfileReviewAssignmentRecuseContract: BaseApiContract<
  SocietyProfileReviewAssignmentParamsInput,
  RecuseAssignmentBodyInput,
  void
> = {
  method: HttpMethod.PATCH,
  apiRoute:
    "/api/v1/society/[societyId]/profiles/[profileId]/review/assignments/[reviewAssignmentId]/recuse",
  paramsType: {} as SocietyProfileReviewAssignmentParamsInput,
  bodyType: {} as RecuseAssignmentBodyInput,
  responseType: undefined as unknown as void,
};
