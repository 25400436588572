import * as Yup from "yup";

export const paymentFormvalidationSchema = Yup.object().shape({
  cardData: Yup.object().shape({
    cardNumber: Yup.string().required("Card number is required"),
    // .matches(/^\d+$/, "Only digits are allowed in the card number")
    // .min(13, "Card number must be at least 13 digits")
    // .max(16, "Card number must be at most 16 digits"),
    cardCode: Yup.string().required("Card code is required"),
    zip: Yup.string().required("ZIP code is required"),
    fullName: Yup.string().required("Full name is required"),
    expMonthYear: Yup.string()
      .required("Expiration month/year is required")
      .test("expirationDate", "Invalid expiration MM/YY format", (value) => {
        if (!value) return true;

        const [month, year] = value.split("/");
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear() % 100;
        const currentMonth = currentDate.getMonth() + 1;

        const isYearValid =
          parseInt(year, 10) > currentYear ||
          (parseInt(year, 10) === currentYear &&
            parseInt(month, 10) >= currentMonth);

        const isMonthValid =
          parseInt(month, 10) >= 1 && parseInt(month, 10) <= 12;

        return isYearValid && isMonthValid;
      }),
  }),
  shippingAddressData: Yup.object().shape({
    line1: Yup.string().required("Address line 1 is required"),
    state: Yup.string().required("State is required"),
    postal_code: Yup.string().required("Postal code is required"),
  }),
  billingAddressData: Yup.object().shape({
    line1: Yup.string().when("isSameAsBilling", (isSameAsBilling, schema) =>
      isSameAsBilling
        ? schema.strip()
        : schema.required("Billing address line 1 is required"),
    ),
    city: Yup.string().when("isSameAsBilling", (isSameAsBilling, schema) =>
      isSameAsBilling
        ? schema.strip()
        : schema.required("Billing city is required"),
    ),
    state: Yup.string().when("isSameAsBilling", (isSameAsBilling, schema) =>
      isSameAsBilling
        ? schema.strip()
        : schema.required("Billing state is required"),
    ),
    postal_code: Yup.string().when(
      "isSameAsBilling",
      (isSameAsBilling, schema) =>
        isSameAsBilling
          ? schema.strip()
          : schema.required("Billing postal code is required"),
    ),
  }),
});
