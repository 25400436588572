import Handlebars from "handlebars";

export type RenderTemplateUtilProps = {
  template: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  templateData: any;
};
export const renderTemplateUtil = ({
  template,
  templateData,
}: RenderTemplateUtilProps): string => {
  try {
    const compiledTemplate = Handlebars.compile(template, { noEscape: true });
    return compiledTemplate(templateData);
  } catch (e: any) {
    if ("message" in e && typeof e.message === "string") {
      return `Error compiling template <br><br><br>${e.message} <br> <br><br><textarea value="${template}"/>`;
    }
    return "Error compiling template";
  }
};
