import { FunctionComponent } from "react";
import { society_icon } from "@prisma/client";
import BookOutlineIcon from "admin/src/ui/components/icons/BookOutlineIcon";
import CashOutlineIcon from "admin/src/ui/components/icons/CashOutlineIcon";
import ChatBubblesOutlineIcon from "admin/src/ui/components/icons/ChatBubblesOutlineIcon";
import EaselOutlineIcon from "admin/src/ui/components/icons/EaselOutlineIcon";
import FileTrayStackedOutlineIcon from "admin/src/ui/components/icons/FileTrayStackedOutlineIcon";
import FolderOpenOutlineIcon from "admin/src/ui/components/icons/FolderOpenOutlineIcon";
import IdCardOutlineIcon from "admin/src/ui/components/icons/IdCardOutlineIcon";
import ListOutlineIcon from "admin/src/ui/components/icons/ListOutlineIcon";
import MailOutlineIcon from "admin/src/ui/components/icons/MailOutlineIcon";
import PeopleOutlineIcon from "admin/src/ui/components/icons/PeopleOutlineIcon";
import RibbonOutlineIcon from "admin/src/ui/components/icons/RibbonOutlineIcon";
import TodayOutlineIcon from "admin/src/ui/components/icons/TodayOutlineIcon";
import FileTrayFullOutlineIcon from "admin/src/ui/components/icons/FileTrayFullOutlineIcon";
import { CommonIconProps } from "admin/src/ui/types/icon";

export const hubIconMapper: Record<
  society_icon,
  FunctionComponent<CommonIconProps>
> = {
  [society_icon.bookOutline]: BookOutlineIcon,
  [society_icon.cashOutline]: CashOutlineIcon,
  [society_icon.chatbubblesOutline]: ChatBubblesOutlineIcon,
  [society_icon.easelOutline]: EaselOutlineIcon,
  [society_icon.folderOpenOutline]: FolderOpenOutlineIcon,
  [society_icon.listOutline]: ListOutlineIcon,
  [society_icon.mailOutline]: MailOutlineIcon,
  [society_icon.peopleOutline]: PeopleOutlineIcon,
  [society_icon.ribbonOutline]: RibbonOutlineIcon,
  [society_icon.idCardOutline]: IdCardOutlineIcon,
  [society_icon.fileTrayFullOutline]: FileTrayFullOutlineIcon,
  [society_icon.todayOutline]: TodayOutlineIcon,
  [society_icon.fileTrayStackedOutline]: FileTrayStackedOutlineIcon,
};
