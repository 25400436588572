export const defaultTemplate = [
  {
    type: "panel",
    name: "panel",
    elements: [
      {
        type: "radiogroup",
        name: "singleChoiceProductQuestion",
        title: "Products",
        isRequired: true,
        choices: [],
      },
      {
        type: "panel",
        name: "multipleChoiceProductPanel",
        elements: [
          {
            type: "text",
            name: "productId",
            visible: false,
          },
          {
            type: "text",
            name: "price",
            visible: false,
          },
          {
            type: "text",
            name: "invoiceId",
            visible: false,
          },
          {
            type: "text",
            name: "invoiceItemId",
            visible: false,
          },
          {
            type: "dropdown",
            name: "quantity",
            visible: false,
          },
        ],
      },
    ],
  },
];
