import {
  DownloadFileEvent,
  QuestionFileModel,
  Serializer,
  SurveyModel,
  UploadFilesEvent,
} from "survey-core";
import { SurveyCreator } from "survey-creator-react";

import { getDownloadFileUrlCallout } from "admin/src/ui/api-callouts/file/get";
import {
  uploadProfileFileCallout,
  uploadSocietyFileCallout,
} from "admin/src/ui/api-callouts/file/upload";
import { FileReference } from "../../../../constants/enums/file-reference";
Serializer.addProperty("FileValue", {
  name: "publicUrl",
});
export const fileUploadSetup = async ({
  societyId,
  profileId,
  model,
  creator,
  baseUrl,
}: {
  societyId: number;
  profileId?: number;
  model: SurveyModel;
  creator?: SurveyCreator;
  baseUrl?: string;
}) => {
  model.onAfterRenderQuestion.add((_, options) => {
    if (options.question.getType() === "file") {
      options.question.storeDataAsText = false;
    }
  });

  const fileDownloadCallback = async (
    surveyModel: SurveyModel,
    downloadEvent: DownloadFileEvent,
  ) => {
    const fileUrl = isNaN(parseInt(downloadEvent.content))
      ? downloadEvent.content
      : await getDownloadFileUrlCallout({
          fileIdOrSlug: downloadEvent.content,
          societyId,
          baseUrl,
        });
    const fileResponse = await fetch(fileUrl);
    const blob = await fileResponse.blob();
    const file = new File([blob], downloadEvent.fileValue.name, {
      type: downloadEvent.fileValue.type,
    });
    const reader = new FileReader();
    reader.onload = (e) => {
      downloadEvent.callback("success", e.target!.result);
    };
    reader.readAsDataURL(file);
  };
  model.onDownloadFile.add(fileDownloadCallback);

  const fileUploadCallback = (
    surveyModel: SurveyModel,
    uploadEvent: UploadFilesEvent,
  ) => {
    const formData = new FormData();

    uploadEvent.files.forEach(async (file) => {
      formData.append(file.name, file);

      const uploadedFile = profileId
        ? await uploadProfileFileCallout({
            file,
            profileId,
            reference: uploadEvent.question.FileReference,
            isPublic: uploadEvent.question.isPublic,
            societyId,
            baseUrl,
          })
        : await uploadSocietyFileCallout({
            file,
            societyId,
            reference: FileReference.Society,
            isPublic: uploadEvent.question.isPublic,
            baseUrl,
          });

      uploadEvent.callback(
        [file].map((file) => {
          return {
            file: file,
            content: uploadedFile?.publicUrl ?? uploadedFile?.fileId,
          };
        }),
      );
    });
  };

  model.onUploadFiles.add(fileUploadCallback);

  if (creator) {
    creator?.onQuestionAdded.add((sender, options) => {
      if (options.question.getType() === "file") {
        const question: QuestionFileModel =
          options.question as unknown as QuestionFileModel;
        question.storeDataAsText = false;
        question.getPropertyByName("storeDataAsText").visible = false;
      }
    });
  }
};
