import { FormikValues, useFormikContext } from "formik";

import React, { ComponentPropsWithoutRef } from "react";

import NewAppInput, { NewAppInputProps } from "./NewAppInput";

export type NewAppTextAreaInputProps = NewAppInputProps &
  Omit<ComponentPropsWithoutRef<"textarea">, "value" | "id">;

const NewAppTextAreaInput = ({
  name,
  labelClassName,
  label,
  additionalClasses,
  onChange,
  ...props
}: NewAppTextAreaInputProps) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  return (
    <NewAppInput
      name={name}
      labelClassName={labelClassName}
      label={label}
      additionalClasses={additionalClasses}
    >
      <textarea
        value={values[name]}
        name={name}
        id={name}
        onChange={(e) => {
          setFieldValue(name, e.target.value);
          onChange?.(e);
        }}
        {...props}
      />
    </NewAppInput>
  );
};

export default NewAppTextAreaInput;
