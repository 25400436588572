import { SurveyModel, TextMarkdownEvent } from "survey-core";
import { SurveyCreatorModel } from "survey-creator-core";

export type OnTextMarkdownApplyHtmlToSurveyContentsProps = {
  creator?: SurveyCreatorModel;
  surveyModel?: SurveyModel;
};
export const onTextMarkdownApplyHtmlToSurveyContents = ({
  creator,
  surveyModel,
}: OnTextMarkdownApplyHtmlToSurveyContentsProps) => {
  if (!creator && !surveyModel) {
    throw new Error("creator or surveyModel is required");
  }
  if (creator) {
    creator.survey.onTextMarkdown.add(applyHtmlToSurveyContents);
    creator.onSurveyInstanceCreated.add((_, options) => {
      if (options.area === "designer-tab" || options.area === "preview-tab") {
        options.survey.onTextMarkdown.add(applyHtmlToSurveyContents);
      }
    });
  }
  if (surveyModel) {
    surveyModel.onTextMarkdown.add(applyHtmlToSurveyContents);
  }
};

const applyHtmlToSurveyContents = (
  sender: SurveyModel,
  options: TextMarkdownEvent,
) => {
  let str = options.text;

  //check if both the first and last characters are <p> and </p> respectively
  if (
    str.charAt(0) === "<" &&
    str.charAt(1) === "p" &&
    str.charAt(2) === ">" &&
    str.charAt(str.length - 1) === ">" &&
    str.charAt(str.length - 2) === "/" &&
    str.charAt(str.length - 3) === "p"
  ) {
    // Remove root paragraphs <p></p>
    str = str.substring(3);
    str = str.substring(0, str.length - 4);
  }
  // Set HTML markup to render
  options.html = str;
};
