import { FileReference } from "admin/src/constants/enums/file-reference";
import { HttpMethod } from "admin/src/ui/types/common/http";

const uploadFile = async ({
  file,
  apiUrl,
  reference,
  isPublic,
}: {
  file: File;
  apiUrl: string;
  reference?: FileReference;
  isPublic?: boolean;
}) => {
  const createFileUploadDataResponse = await fetch(apiUrl, {
    method: HttpMethod.POST,
    body: JSON.stringify({
      file: {
        name: file.name,
        type: file.type,
      },
      ...(reference && { reference }),
      ...(isPublic
        ? { isPublic }
        : {
            isPublic: false,
          }),
    }),
    headers: { "Content-Type": "application/json" },
  });
  const jsonResponse = await createFileUploadDataResponse.json();

  const { signedUrl, publicUrl, id } = jsonResponse.body || jsonResponse;

  if (signedUrl) {
    await fetch(signedUrl, {
      method: HttpMethod.PUT,
      headers: {
        "Content-Type": file.type,
      },
      body: file,
    });
    return { signedUrl, publicUrl, fileId: id };
  }
};

export const uploadProfileFileCallout = async ({
  societyId,
  file,
  profileId,
  reference,
  isPublic,
  baseUrl,
}: {
  societyId: number;
  file: File;
  profileId: number;
  reference: FileReference;
  isPublic?: boolean;
  baseUrl?: string;
}) => {
  try {
    const apiUrl = `${
      baseUrl ?? ""
    }/api/v1/society/${societyId}/profiles/${profileId}/files`;

    return await uploadFile({ file, apiUrl, reference, isPublic });
  } catch (error) {
    console.error("Failed to get presigned URL", error);
  }
};

export const uploadGroupFileCallout = async ({
  file,
  groupId,
  reference,
  societyId,
}: {
  file: File;
  groupId: number;
  reference: FileReference;
  societyId: number;
}) => {
  try {
    const apiUrl = `/api/v1/society/${societyId}/groups/${groupId}/files`;

    return await uploadFile({ file, apiUrl, reference });
  } catch (error) {
    console.error("Failed to get presigned URL", error);
  }
};
export const uploadSocietyFileCallout = async ({
  file,
  reference,
  isPublic,
  baseUrl,
  societyId,
}: {
  file: File;
  reference: FileReference;
  isPublic?: boolean;
  baseUrl?: string;
  societyId: number;
}) => {
  try {
    const apiUrl = `${baseUrl ?? ""}/api/v1/society/${societyId}/files`;

    return await uploadFile({ file, apiUrl, reference, isPublic });
  } catch (error) {
    console.error("Failed to get presigned URL", error);
  }
};

export const uploadSubmissionFileCallout = async ({
  file,
  reference,
  societyId,
}: {
  file: File;
  reference: FileReference;
  societyId: number;
}) => {
  try {
    const apiUrl = `/api/v1/society/${societyId}/submission/files`;

    return await uploadFile({ file, apiUrl, reference });
  } catch (error) {
    console.error("Failed to get presigned URL", error);
  }
};

export const uploadReviewSessionFileCallout = async ({
  file,
  reference,
  societyId,
}: {
  file: File;
  reference: FileReference;
  societyId: number;
}) => {
  try {
    const apiUrl = `/api/v1/society/${societyId}/review/session/files`;

    return await uploadFile({ file, apiUrl, reference });
  } catch (error) {
    console.error("Failed to get presigned URL", error);
  }
};

export type UploadFileCalloutProps = {
  file: File;
  societyId: number;
};
export const uploadFileCallout = async ({
  file,
  societyId,
}: UploadFileCalloutProps) => {
  try {
    const apiUrl = `/api/v1/society/${societyId}/files`;

    return await uploadFile({
      file,
      apiUrl,
      reference: FileReference.Society,
      isPublic: true,
    });
  } catch (error) {
    console.error("Failed to get presigned URL", error);
  }
};
