import { useState } from "react";
import { Question } from "survey-core";

export type ObfuscateInputProps = {
  question: Question;
  onChange: (value: string) => void;
  regexPattern: RegExp;
  obfuscatePattern: string;
};

const ObfuscateInput = ({
  question,
  onChange,
  regexPattern,
  obfuscatePattern,
}: ObfuscateInputProps) => {
  const value = question.value || "";
  const [displayValue, setDisplayValue] = useState(value);

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayValue(
      e.target.value.replace(new RegExp(regexPattern), obfuscatePattern),
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    setDisplayValue(newValue);
    onChange(newValue);
  };

  return (
    <input
      type="text"
      value={displayValue}
      onBlur={handleBlur}
      onChange={handleChange}
      className="sd-input sd-text"
    />
  );
};

export default ObfuscateInput;
