/* eslint-disable @typescript-eslint/no-explicit-any */

import { defaultTemplate } from "admin/src/ui/components/surveyJS/shared/products/defaultTemplate";
import {
  addQuantityQuestion,
  setQuantityValueOnSurvey,
} from "admin/src/ui/components/surveyJS/shared/products/products-question-utils/addQuantityQuestion";
import { generateIteratedQuestionName } from "admin/src/ui/components/surveyJS/utils/geIteratedQuestionName";
import { formatUsdString } from "admin/src/utils/helpers/invoice/formatUsdString";
import { SessionView } from "shared/mappers/database/session/session";
import {
  ComponentCollection,
  ItemValue,
  PanelModel,
  QuestionCheckboxModel,
  QuestionCompositeModel,
  QuestionPanelDynamicModel,
  QuestionRadiogroupModel,
  QuestionTextModel,
  Serializer,
} from "survey-core";
import { QuestionAddedEvent, SurveyCreatorModel } from "survey-creator-core";

export const ProductsSurveyJSQuestionName = "products";
export type ProductsSurveyJSQuestionProps = {
  session?: SessionView;
  creator?: SurveyCreatorModel;
};

export type ProductsSurveyJSQuestionProductOption = {
  value: number; //VALUE IS PRODUCT ID.  SURVEYJS REQUIRES A VALUE A PRODUCT OPTION'S VALUE IS THE PRODUCT ID
  price?: number;
  showPrice: boolean;
  userPriceOverride: boolean;
  maximumQuantity?: number | undefined;
  minumumQuantity?: number | undefined;
  text: string;
  visibleIf?: string;
  enableIf?: string;
};
export type ProductsSurveyJSQuestionValueBase = {
  productId: number;
  price?: number;
  quantity: number;
  invoiceId?: number;
  invoiceItemId?: number;
};

export type ProductsSurveyJSQuestionValueSingle = {
  singleChoiceProductQuestion: number;
} & ProductsSurveyJSQuestionValueBase;

export type ProductsSurveyJSQuestionValueMultiple = {
  multipleChoiceProduct: number[];
  multipleChoiceProductPanel: ProductsSurveyJSQuestionValueBase[];
};

export type ProductsSurveyJSQuestionValue =
  | ProductsSurveyJSQuestionValueSingle
  | ProductsSurveyJSQuestionValueMultiple;
enum ProductsSurveyJSQuestionProperties {
  multipleChoice = "multipleChoice",
  options = "options",
}

export const ProductsSurveyJSQuestionRegisterProperties = (
  session?: SessionView,
) => {
  Serializer.addProperty(ProductsSurveyJSQuestionName, {
    name: ProductsSurveyJSQuestionProperties.multipleChoice,
    displayName: "Multiple Choice",
    type: "boolean",
    categoryIndex: 0,
    visibleIndex: 0,
    category: "Product Question",
    default: false,
  });

  Serializer.addClass(
    "productitemvalue",
    [
      {
        name: "value",
        type: "dropdown",
        choices: session?.society?.products?.map((product) => ({
          value: product.id,
          text: product.name,
        })),
      },
      { name: "text", type: "string", showMode: "form" },
      { name: "price", type: "number", showMode: "form" },
      { name: "showPrice", type: "boolean", showMode: "form" },
      { name: "userPriceOverride", type: "boolean", showMode: "form" },
      {
        name: "minumumQuantity",
        type: "number",
        minValue: 1,
        showMode: "form",
      },
      {
        name: "maximumQuantity",
        type: "number",
        maxValue: 999,
        showMode: "form",
      },
    ],
    undefined,
    "itemvalue",
  );
  Serializer.addProperty(ProductsSurveyJSQuestionName, {
    name: ProductsSurveyJSQuestionProperties.options,
    type: "productitemvalue[]",
    visible: true,
    categoryIndex: 0,
    visibleIndex: 1,
    category: "Product Question",
  });
};

export const ProductsSurveyJSQuestion = async ({
  session,
  creator,
}: ProductsSurveyJSQuestionProps) => {
  ProductsSurveyJSQuestionRegisterProperties(session);
  creator?.onQuestionAdded.add(
    (sender: SurveyCreatorModel, options: QuestionAddedEvent) => {
      if (options.question?.getType() == ProductsSurveyJSQuestionName) {
        options.question.name = generateIteratedQuestionName(
          ProductsSurveyJSQuestionName,
          sender,
        );
      }
    },
  );

  const ProductsSurveyJSQuestionDefinition = {
    name: ProductsSurveyJSQuestionName,
    title: "Products",
    createElements: () => {
      return [];
    },
    onInit: async () => {
      ProductsSurveyJSQuestionRegisterProperties();
    },
    onItemValuePropertyChanged(
      question: QuestionCompositeModel,
      options: {
        obj: ItemValue;
        propertyName: string;
        name: string;
        newValue: any;
      },
    ): void {
      if (
        options.propertyName === ProductsSurveyJSQuestionProperties.options &&
        options.name === "value" &&
        options.obj["text"] == options.newValue
      ) {
        const product = session?.society?.products?.find(
          (product) => product.id === options.newValue,
        );
        options.obj["text"] = product?.name ?? "ERROR: No Product Name";
      }
      buildProductQuestionElements(question);
    },
    onValueChanged: (
      question: QuestionCompositeModel,
      name: string,
      newValue: any,
    ) => {
      const value = question.value as ProductsSurveyJSQuestionValue;
      const getQuestionByName = (name: string) =>
        question.contentPanel.getQuestionByName(name);
      if ("multipleChoiceProduct" in value) {
        const selectedProductIds = value.multipleChoiceProduct;
        const multipleChoiceProductPanel = getQuestionByName(
          "multipleChoiceProductPanel",
        ) as QuestionPanelDynamicModel;
        if (selectedProductIds.length) {
          multipleChoiceProductPanel.visible = true;
        } else {
          multipleChoiceProductPanel.visible = false;
        }

        selectedProductIds.forEach((productId, panelIdx) => {
          const productOption: ProductsSurveyJSQuestionProductOption =
            question.options.find(
              (option: ProductsSurveyJSQuestionProductOption) =>
                option.value === productId,
            );
          const panel =
            panelIdx < multipleChoiceProductPanel.panels.length
              ? multipleChoiceProductPanel.panels[panelIdx]
              : multipleChoiceProductPanel.addPanel();

          if (productOption.minumumQuantity || productOption.maximumQuantity) {
            addQuantityQuestion({
              panel,
              productOption,
              isDropdown: true,
              question,
            });
          }
        });
      } else if ("singleChoiceProductQuestion" in value) {
        const productId = value.singleChoiceProductQuestion;
        const selectedProduct = question.options.find(
          (option: ProductsSurveyJSQuestionProductOption) =>
            option.value === productId,
        );

        if (
          selectedProduct.minumumQuantity ||
          selectedProduct.maximumQuantity
        ) {
          addQuantityQuestion({
            panel: question.contentPanel,
            productOption: selectedProduct,
            question,
            isDropdown: !!(
              selectedProduct?.minumumQuantity &&
              selectedProduct?.maximumQuantity
            ),
          });
        } else {
          question.contentPanel.removeElement(
            question.contentPanel.getQuestionByName("quantity"),
          );
        }
      }

      if (name == "multipleChoiceProductPanel" && newValue.quantity) {
        setQuantityValueOnSurvey({
          question,
          newValue: newValue.quantity,
          productId: newValue.productId,
        });
      } else if (name == "quantity") {
        setQuantityValueOnSurvey({
          question,
          newValue,
          productId: question.value.productId,
        });
      }
    },

    onValueChanging: (
      question: QuestionCompositeModel,
      name: string,
      newValue: any,
    ) => {
      if (name === "multipleChoiceProduct") {
        const selectedProducts = newValue as number[];

        const dynamicPanel = <QuestionPanelDynamicModel>(
          question.contentPanel.getQuestionByName("multipleChoiceProductPanel")
        );
        dynamicPanel.visible = false;
        const existingValues = dynamicPanel?.value || [];
        const updatedValues = selectedProducts.map((productId) => {
          const productOption: ProductsSurveyJSQuestionProductOption =
            question.options.find(
              (option: ProductsSurveyJSQuestionProductOption) =>
                option.value === productId,
            );
          const existingProduct = existingValues.find(
            (value: ProductsSurveyJSQuestionValueBase) =>
              value.productId === productId,
          );

          return {
            value: productId,
            productId: productId,
            price: productOption.price,
            quantity:
              existingProduct?.quantity ?? productOption.minumumQuantity ?? 1,
          };
        });

        if (dynamicPanel) {
          dynamicPanel.value = updatedValues;
        }
      } else if (name === "singleChoiceProductQuestion") {
        const productItemValue = question.options.find(
          (option: ProductsSurveyJSQuestionProductOption) =>
            option.value === newValue,
        );
        question.setValue(
          "quantity",
          productItemValue.minumumQuantity,
          true,
          true,
        );
        question.setValue("productId", productItemValue.value, true, true);
        question.setValue("price", productItemValue.price, true, true);
      }
      return newValue;
    },
    onAfterRender: (question: QuestionCompositeModel) => {
      const survey: any = question?.survey;

      if (question.getType() !== "products" || !survey?.formData) return;

      const existingProducts =
        survey.formData[question.name]?.multipleChoiceProductPanel ||
        survey.formData[question.name];

      if (Array.isArray(existingProducts)) {
        existingProducts.forEach((product: any, index: number) => {
          if (product?.quantity !== undefined) {
            question.setValue(
              `multipleChoiceProductPanel[${index}].quantity`,
              product.quantity,
              true,
              true,
            );
          }
        });
      } else if (existingProducts?.quantity !== undefined) {
        question.setValue("quantity", existingProducts.quantity, true, true);
      }
    },
    onLoaded: (question: QuestionCompositeModel) => {
      buildProductQuestionElements(question);
    },

    elementsJSON: defaultTemplate,
  };

  const buildProductQuestionChoiceText = (
    option: ProductsSurveyJSQuestionProductOption,
  ) => {
    const product = session?.society?.products?.find(
      (product) => product.id === option.value,
    );
    const formattedPrice = formatUsdString(option.price ?? product?.price ?? 0);
    if (option.showPrice) {
      return `${
        option.text ?? product?.name ?? "ERROR: No Product Name OR Description"
      } - ${formattedPrice}`;
    }
    return (
      option.text ?? product?.name ?? "ERROR: No Product Name OR Description"
    );
  };

  const buildProductQuestionElements = (question: QuestionCompositeModel) => {
    const panel = <PanelModel>question["contentPanel"];
    if (!panel) {
      return;
    }
    for (const element of panel.elements) {
      panel.removeElement(element);
    }
    buildProductQuestionElementsMultipleChoice(question);
    buildProductQuestionElementsSingleChoice(question);
  };

  const buildProductQuestionElementsMultipleChoice = (
    question: QuestionCompositeModel,
  ) => {
    const panel = <PanelModel>question["contentPanel"];
    if (!panel) {
      return;
    }

    const { multipleChoice } = question;
    const options = question.options as ProductsSurveyJSQuestionProductOption[];

    if (multipleChoice === true) {
      const multipleChoiceProductQuestion = new QuestionCheckboxModel(
        "multipleChoiceProduct",
      );
      multipleChoiceProductQuestion.title = "";
      multipleChoiceProductQuestion.titleLocation = "hidden";
      multipleChoiceProductQuestion.valueName = "multipleChoiceProduct";
      multipleChoiceProductQuestion.isEditableTemplateElement = false;
      multipleChoiceProductQuestion.isInteractiveDesignElement = false;
      multipleChoiceProductQuestion.isContentElement = true;

      const questionValue = <ProductsSurveyJSQuestionValueMultiple | undefined>(
        question.value
      );

      multipleChoiceProductQuestion.choices = options?.map(
        (option: ProductsSurveyJSQuestionProductOption, index: number) => {
          const currentInvoiceMatchingProductId =
            questionValue?.multipleChoiceProductPanel?.find(
              (productValue) =>
                productValue.productId === option.value &&
                productValue.invoiceId,
            );
          if (!session?.adminMode && currentInvoiceMatchingProductId) {
            multipleChoiceProductQuestion.readOnly = true;
          }
          const itemValue = new ItemValue(
            option.value, // index + 1,
            buildProductQuestionChoiceText(option),
          );
          if (option.visibleIf && option.visibleIf !== "") {
            itemValue.visibleIf = option.visibleIf;
          }
          if (option.enableIf && option.enableIf !== "") {
            itemValue.enableIf = option.enableIf;
          }
          setQuantityValueOnSurvey({
            question,
            newValue: option.minumumQuantity ?? 1,
            productId: option.value,
          });
          return itemValue;
        },
      );

      panel.addQuestion(multipleChoiceProductQuestion);

      // Add a hidden dynamic panel for storing product details
      const productsPanel = new QuestionPanelDynamicModel(
        "multipleChoiceProductPanel",
      );
      productsPanel.titleLocation = "hidden";
      productsPanel.isEditableTemplateElement = false;
      productsPanel.isInteractiveDesignElement = false;
      productsPanel.isContentElement = true;
      productsPanel.visible = false;
      productsPanel.clearIfInvisible = "none";
      productsPanel.allowAddPanel = false;
      productsPanel.allowRemovePanel = false;

      ["productId", "price", "invoiceId", "invoiceItemId"].forEach((name) => {
        const hiddenTextQuestion = new QuestionTextModel(name);
        hiddenTextQuestion.visible = false;
        hiddenTextQuestion.clearIfInvisible = "none";
        hiddenTextQuestion.isEditableTemplateElement = false;
        hiddenTextQuestion.isInteractiveDesignElement = false;
        hiddenTextQuestion.isContentElement = true;
        productsPanel.template.addElement(hiddenTextQuestion);
      });

      panel.addElement(productsPanel);
    }
  };

  const buildProductQuestionElementsSingleChoice = (
    question: QuestionCompositeModel,
  ) => {
    const panel = question.contentPanel;
    if (!panel) {
      return;
    }

    const { multipleChoice } = question;
    const options = question.options as ProductsSurveyJSQuestionProductOption[];

    if (multipleChoice !== true) {
      const singleChoiceProductQuestion = new QuestionRadiogroupModel(
        "singleChoiceProductQuestion",
      );
      singleChoiceProductQuestion.titleLocation = "hidden";
      singleChoiceProductQuestion.isEditableTemplateElement = false;
      singleChoiceProductQuestion.isInteractiveDesignElement = false;
      singleChoiceProductQuestion.isContentElement = true;

      const questionValue = <ProductsSurveyJSQuestionValueSingle | undefined>(
        question?.getAllValues()?.[question.name]
      );

      singleChoiceProductQuestion.choices = options?.map(
        (option: ProductsSurveyJSQuestionProductOption, index: number) => {
          if (!session?.adminMode && questionValue?.invoiceId) {
            singleChoiceProductQuestion.readOnly = true;
          }
          const itemValue = new ItemValue(
            option.value,
            buildProductQuestionChoiceText(option),
          );
          if (option.visibleIf && option.visibleIf !== "") {
            itemValue.visibleIf = option.visibleIf;
          }
          if (option.enableIf && option.enableIf !== "") {
            itemValue.enableIf = option.enableIf;
          }
          setQuantityValueOnSurvey({
            question,
            newValue: option.minumumQuantity ?? 1,
            productId: option.value,
          });
          return itemValue;
        },
      );

      if (
        !panel.elements.find(
          (element) => element.name == "singleChoiceProductQuestion",
        )
      ) {
        panel.addElement(singleChoiceProductQuestion);
      }

      ["productId", "price", "invoiceId", "invoiceItemId"].forEach((name) => {
        if (!panel.elements.find((element) => element.name == name)) {
          const questionTextModel = new QuestionTextModel(name);
          questionTextModel.value =
            questionValue?.[name as keyof ProductsSurveyJSQuestionValueSingle];
          questionTextModel.clearIfInvisible = "none";
          questionTextModel.visible = false;
          questionTextModel.isEditableTemplateElement = false;
          questionTextModel.isInteractiveDesignElement = false;
          questionTextModel.isContentElement = true;
          panel.addElement(questionTextModel);
        }
      });
    }
  };

  if (
    !ComponentCollection.Instance.getCustomQuestionByName(
      ProductsSurveyJSQuestionName,
    )
  ) {
    ComponentCollection.Instance.add(ProductsSurveyJSQuestionDefinition);
  }
};
